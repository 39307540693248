import { Injectable, Injector } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { AppRouteGuard } from '@app/shared/common/auth/auth-route-guard';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { PermissionCheckerService, RefreshTokenService } from 'abp-ng2-module';
import { of } from 'rxjs';
import { PermissionsAndFeaturesResolverService } from '@shared/permissions-and-features-resolver/permissions-and-features-resolver.service';

@Injectable({ providedIn: 'root' })
export class UserAgreementCanActivateRoute extends AppRouteGuard implements CanActivate {
    canActivate() {
        if (this.sessionService.tenant.eulaSigned) {
            this.router.navigate([this.selectBestRoute()]);

            return of(false);
        } else {
            return of(true);
        }
    }

    constructor(
        private injector: Injector,
        private router: Router,
        permissionChecker: PermissionCheckerService,
        private sessionService: AppSessionService,
        refreshTokenService: RefreshTokenService,
        permissionsAndFeaturesResolverService: PermissionsAndFeaturesResolverService
    ) {
        super(permissionChecker, router, sessionService, refreshTokenService, permissionsAndFeaturesResolverService);
    }
}

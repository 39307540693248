import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UserFeedbackComponent } from './user-feedback.component';
import { UserFeedbackModalComponent } from './user-feedback-modal/user-feedback-modal.component';
import { SharedModalModule } from '@app/main/shared/modals/shared-modal/shared-modal.module';
import { SharedFormComponents } from '@app/main/shared/for-forms/components/shared-form-components.module';
import { UtilsModule } from '@shared/utils/utils.module';

@NgModule({
    imports: [CommonModule, SharedModalModule, SharedFormComponents, UtilsModule],
    declarations: [UserFeedbackComponent, UserFeedbackModalComponent],
    exports: [UserFeedbackComponent, UserFeedbackModalComponent],
})
export class UserFeedbackModule {}

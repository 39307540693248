import { MainModuleStoreService } from './../../../../main/main-module-store.service';
import { Component, ChangeDetectionStrategy } from '@angular/core';

@Component({
    selector: 'app-download-status',
    templateUrl: './download-status.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DownloadStatusComponent {
    constructor(public mainModuleStoreService: MainModuleStoreService) {}
}

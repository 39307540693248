import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FileUploadService } from '@app/main/projects/modals/upload-files/file-upload-wrapper/file-upload-wrapper.service';
import { UploaderComponentStatus } from '@app/main/projects/modals/upload-files/file-upload-wrapper/models/enums/uploader-component-status.enum';

@Component({
    selector: 'app-file-upload-button',
    templateUrl: './file-upload-button.component.html',
    styleUrls: ['./file-upload-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadButtonComponent {
    constructor(public fileUploadService: FileUploadService) {}

    onClick() {
        this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.modal;
    }
}

import { Injectable } from '@angular/core';

/**
 * Store here only values that are not meant to be changed at application runtime.
 * Preferably `static readonly` properties only + short description.
 */
@Injectable({ providedIn: 'root' })
export class MainModuleConstantsService {
    /**
     * Limit that is used at entity creation time.
     */
    static readonly descriptionCharactersLimit = 500;

    /**
     * Pattern that is used to validate names during entity creation. Agreed on converge time in Oct 22'
     */
    static readonly patternValidationRegExp = new RegExp("^[A-Za-z0-9^!#$%&'()*+,-.\\/:;<=>?@[\\]^_`{|}~ ]+$");
}

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'hideZptFileExtension',
})
export class HideZptFileExtensionPipe implements PipeTransform {
    transform(value: string): string {
        if (value.slice(-4) === '.ZPT') {
            return value.slice(0, -4);
        }

        return value;
    }
}

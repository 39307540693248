import { Injectable } from '@angular/core';
import {
    DataVisualizationsServiceProxy,
    GetDataVisualizationForViewDto,
} from '@shared/service-proxies/service-proxies';
import { ApiRequestArguments } from '@shared/utils/api-request-arguments.service';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class DataVisualizationService {
    firstActiveDataVisualization: GetDataVisualizationForViewDto;

    set allVisualizations(value: GetDataVisualizationForViewDto[]) {
        this._allVisualizations = value;
    }
    get allVisualizations(): GetDataVisualizationForViewDto[] {
        return this._allVisualizations;
    }

    private _allVisualizations: GetDataVisualizationForViewDto[] = [];

    constructor(private dataVisualizationsServiceProxy: DataVisualizationsServiceProxy) {}

    public getAllDataVisualizations() {
        this.dataVisualizationsServiceProxy
            .getAll(...ApiRequestArguments.defaultDataVisualizationsGetAll)
            .pipe(catchError(() => EMPTY))
            .subscribe(({ items }) => {
                this._allVisualizations = items;

                this.firstActiveDataVisualization = this._allVisualizations.find(
                    ({ dataVisualization: { isActive } }) => isActive === true
                );
            });
    }
}

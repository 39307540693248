import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { AppUrlService } from './nav/app-url.service';
import { AppUiCustomizationService } from './ui/app-ui-customization.service';
import { AppSessionService } from './session/app-session.service';
import { CookieConsentService } from './session/cookie-consent.service';
import { LoadingSpinnerComponent } from '@app/shared/layout/loading-spinner/loading-spinner.component';
import { SafePipe } from '@app/shared/common/pipes/safe-html.pipe';

@NgModule({
    declarations: [LoadingSpinnerComponent, SafePipe],
    imports: [CommonModule],
    providers: [SafePipe],
    exports: [LoadingSpinnerComponent, SafePipe],
})
export class CytoSolverCommonModule {
    static forRoot(): ModuleWithProviders<CommonModule> {
        return {
            ngModule: CommonModule,
            providers: [AppUiCustomizationService, CookieConsentService, AppSessionService, AppUrlService],
        };
    }
}

import { NgModule } from '@angular/core';
import { SharedModalComponent } from './shared-modal.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ModalModule, CommonModule],
    declarations: [SharedModalComponent],
    exports: [SharedModalComponent],
})
export class SharedModalModule {}

import { filter, takeUntil } from 'rxjs/operators';
import { SharedInputPropertiesDirective } from './../shared-input-properties.directive';
import { Component, ChangeDetectionStrategy, ViewEncapsulation, Injector, Input, OnInit } from '@angular/core';
import { ErrorDef } from '@shared/utils/validation-messages.component';
import { timer, Subject, Observable } from 'rxjs';
import { MainModuleConstantsService } from '@app/main/shared/main-module-constants.service';

@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./../shared-input-styles.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextInputComponent extends SharedInputPropertiesDirective implements OnInit {
    @Input() sanitizeOnChange = false;
    @Input() isWhitespaceTrimmerUsed = false;

    popoverText: string;
    isPopoverVisible = false;

    readonly errorDefs: ErrorDef[] = [
        { error: 'required', localizationKey: 'Please specify a name', errorProperty: undefined },
        {
            error: 'checkExistingInCollection',
            localizationKey: 'This name already exists in the collection',
            errorProperty: undefined,
        },
        {
            error: 'checkExistingInCollectionForProjects',
            localizationKey: 'This project already exists',
            errorProperty: undefined,
        },
        {
            error: 'checkExistingInCollectionForDesigns',
            localizationKey: 'This design already exists',
            errorProperty: undefined,
        },
        {
            error: 'pattern',
            localizationKey: 'Please use only allowed characters',
            errorProperty: undefined,
        },
    ];

    private resetTimer$ = new Subject();
    private controlValueChanged$: Observable<string | null>;

    constructor(injector: Injector) {
        super(injector);
    }

    ngOnInit() {
        super.ngOnInit();

        if (this.control) {
            this.controlValueChanged$ = this.control.valueChanges.pipe(
                takeUntil(this.componentDestroyed$),
                filter((v) => !!v)
            );
        }

        this.sliceControlValueToValidatorRequiredLength();

        if (this.sanitizeOnChange) {
            this.applyInputSanitizationAndShowPopover();
        }
    }

    private sliceControlValueToValidatorRequiredLength() {
        this.controlValueChanged$.subscribe((v) => {
            if (this.control.errors?.maxlength) {
                this.control.patchValue(v.slice(0, this.control.errors.maxlength.requiredLength));
            }
        });
    }

    private applyInputSanitizationAndShowPopover() {
        this.controlValueChanged$.subscribe((v: string) => {
            const isForbiddenCharacterUsed = MainModuleConstantsService.patternValidationRegExp.test(v) === false;

            if (isForbiddenCharacterUsed) {
                const forbiddenCharacters: string[] = [];

                const valueWithoutForbiddenCharacter = Array.from(v).reduce((prev, curr) => {
                    const isCharacterValid = MainModuleConstantsService.patternValidationRegExp.test(curr);

                    if (isCharacterValid) {
                        return prev + curr;
                    } else {
                        forbiddenCharacters.push(curr);
                        return prev;
                    }
                }, '');

                this.isPopoverVisible = false;
                this.cdRef.detectChanges();

                this.popoverText = `The name cannot contain any of the following characters: "${forbiddenCharacters.join(
                    ', '
                )}"`;

                this.isPopoverVisible = true;
                this.cdRef.detectChanges();

                this.resetTimer$.next();

                this.control.patchValue(valueWithoutForbiddenCharacter);

                timer(4000)
                    .pipe(takeUntil(this.resetTimer$))
                    .subscribe(() => {
                        this.isPopoverVisible = false;
                        this.cdRef.detectChanges();
                    });
            }
        });
    }
}

<button class="btn btn-clean btn-icon btn-lg mx-2" title="Submit your feedback" (click)="openModal()">
    <i
        class="fa-duotone fa-message-captions"
        style="
            --fa-primary-color: #ef8100;
            --fa-primary-opacity: 0.8;
            --fa-secondary-color: #ef8100;
            --fa-secondary-opacity: 0.3;
        "
    ></i>
</button>

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * A place to store data related to main module. Because it's general, avoid overusing it. Try looking for more specific store
 * or create new one. Consider NgRx implementation
 */
@Injectable({
    providedIn: 'root',
})
export class MainModuleStoreService {
    private _csdInstallerDownloadProgress$ = new BehaviorSubject<number | null>(null);
    csdInstallerDownloadProgress$ = this._csdInstallerDownloadProgress$.asObservable();

    private _userManualDownloadProgress$ = new BehaviorSubject<number | null>(null);
    userManualDownloadProgress$ = this._userManualDownloadProgress$.asObservable();

    /**
     * @param progress in percents (0 - 100). `null` means not active (not started or already finished)
     */
    updateCsdInstallerDownloadProgress(progress: number | null) {
        this._csdInstallerDownloadProgress$.next(progress);
    }

    /**
     * @param progress in percents (0 - 100). `null` means not active (not started or already finished)
     */
    updateUserManualDownloadProgress(progress: number | null) {
        this._userManualDownloadProgress$.next(progress);
    }
}

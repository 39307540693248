import { Directive } from '@angular/core';
import { Table } from 'primeng/table';
import { LazyLoadEvent } from '@node_modules/primeng/api';
import { DateTime } from 'luxon';
import {
    ExperimentDesignDto,
    GetProjectForViewDto,
    GetZonePlotTraceFileForViewDto,
    ProjectDto,
} from '@shared/service-proxies/service-proxies';
import { catchStatementLogger } from '@shared/helpers/CatchStatementLogger';

@Directive({
    selector: '[appClientSideSort]',
})
export class ClientSideSortDirective {
    constructor(private table: Table) {}

    shouldUseSort(event?: LazyLoadEvent): boolean {
        return !!event.sortField;
    }

    sort(event: LazyLoadEvent) {
        this.table.value = this.table.value.sort((a, b) => {
            let firstProperty;
            let secondProperty;

            if (a instanceof GetZonePlotTraceFileForViewDto) {
                if (event.sortField === 'projectName') {
                    firstProperty = a[event.sortField];
                    secondProperty = b[event.sortField];
                } else {
                    firstProperty = a.zonePlotTraceFile[event.sortField];
                    secondProperty = b.zonePlotTraceFile[event.sortField];
                }
            }

            if (a instanceof GetProjectForViewDto) {
                firstProperty = a.project[event.sortField];
                secondProperty = b.project[event.sortField];
            }

            if (a instanceof ProjectDto) {
                firstProperty = a[event.sortField];
                secondProperty = b[event.sortField];
            }

            if (a instanceof ExperimentDesignDto) {
                firstProperty = a[event.sortField];
                secondProperty = b[event.sortField];
            }

            if (a instanceof DateTime || firstProperty instanceof DateTime) {
                if (firstProperty <= secondProperty) {
                    return event.sortOrder * -1;
                }

                return event.sortOrder;
            }

            if (firstProperty === undefined && secondProperty === undefined) {
                catchStatementLogger(
                    'client-side-sort.directive.ts',
                    'Both properties for sort not recognized, default sorting applied.'
                );
            }

            return event.sortOrder * ('' + firstProperty).localeCompare(secondProperty);
        });
    }
}

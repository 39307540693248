import { MessageService } from 'abp-ng2-module';
import { UserFeedbackDto, UserFeedbackServiceProxy } from './../../../../../../shared/service-proxies/service-proxies';
import { Title } from '@angular/platform-browser';
import { AppSessionService } from '@shared/common/session/app-session.service';
import { Component, ChangeDetectionStrategy, ViewChild, ChangeDetectorRef } from '@angular/core';
import { FormControl, MinLengthValidator, Validators } from '@angular/forms';
import { SharedModalComponent } from '@app/main/shared/modals/shared-modal/shared-modal.component';
import { DateTime } from 'luxon';
import { catchError, finalize, switchMap } from 'rxjs/operators';
import { EMPTY } from 'rxjs';
import { environment } from 'environments/environment';

@Component({
    selector: 'app-user-feedback-modal',
    templateUrl: './user-feedback-modal.component.html',
    styleUrls: ['./user-feedback-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFeedbackModalComponent {
    @ViewChild('sharedModal') sharedModal: SharedModalComponent;

    descriptionControl = new FormControl('', [Validators.minLength(30)]);

    constructor(
        private cdRef: ChangeDetectorRef,
        private appSessionService: AppSessionService,
        private title: Title,
        private userFeedbackServiceProxy: UserFeedbackServiceProxy,
        private message: MessageService
    ) {}

    open() {
        this.sharedModal.show();
        this.cdRef.markForCheck();
    }

    closeAndResetFormValues() {
        this.sharedModal.hide().subscribe(() => {
            this.descriptionControl.reset();
        });
    }

    send() {
        this.sharedModal.saving = true;

        this.userFeedbackServiceProxy
            .sendUserFeedback(this.getDataToSend())
            .pipe(
                switchMap(() => this.sharedModal.hide()),
                catchError(() => {
                    this.message.error('Something went wrong, please try again later');
                    return EMPTY;
                }),
                finalize(() => {
                    this.sharedModal.saving = false;
                    this.cdRef.detectChanges();
                })
            )
            .subscribe(() => {
                this.message.success('Thank you for your feedback!');
                this.descriptionControl.reset();
            });
    }

    private getDataToSend(): UserFeedbackDto {
        return new UserFeedbackDto({
            user: `${this.appSessionService.user.userName} (${this.appSessionService.user.name} ${this.appSessionService.user.surname}), id: ${this.appSessionService.user.id}`,
            tenancy: `${this.appSessionService.tenancyName}, id: ${this.appSessionService.tenantId}`,
            time: DateTime.now(),
            sessionLength: DateTime.now().diff(DateTime.fromJSDate(abp.pageLoadTime)).toISOTime(),
            description: this.descriptionControl.value,
            fromUrl: `(environment: ${environment.remoteEnvironmentName}) ${
                window.location.href.split('app/')[1]
            } (page title: ${this.title.getTitle()})`,
            userAgent: navigator.userAgent,
        });
    }
}

import { Router } from '@angular/router';

/**
 * Modified `navigate` method allows to re-render component on same route, but with different parameters.
 * This is prevented by default behavior of `RouteReuseStrategy`.
 * See more: https://angular.io/api/router/RouteReuseStrategy
 *
 * Contains extra state that can be used in example in route guards (helps to early return for the first navigate,
 * preventing double guard logic activation)
 */
export class NavigateWithReRender {
    static navigate(router: Router, path: string[]) {
        router
            .navigateByUrl('/app' + path, { skipLocationChange: true, state: { navigateWithReRender: true } })
            .then(() => {
                router.navigate(path);
            });
    }
}

<label class="form-field__label-wrapper" [for]="label">
    <span class="text-12px text-csc-hard-grey">
        {{ label }}
        <span *ngIf="hasRequiredAsterisk" class="text-danger">*</span>
    </span>

    <validation-messages *ngIf="hasValidationMessage && control.touched" [formCtrl]="control"></validation-messages>
</label>

<textarea
    type="text"
    class="form-field__input-element form-field__input-element--textarea form-control"
    [id]="label"
    [formControl]="control"
></textarea>

<div class="text-right text-black-25 text-10px mt-1">{{ charactersLimit - control.value?.length }} characters left</div>

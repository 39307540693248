<ng-container [ngSwitch]="item.type">
    <app-sidebar-instance-with-subelements
        *ngSwitchCase="sidebarInstancesTypes.project"
        [item]="item"
    ></app-sidebar-instance-with-subelements>

    <app-sidebar-instance-single
        *ngSwitchCase="sidebarInstancesTypes.experimentDesign"
        [item]="item"
    ></app-sidebar-instance-single>
</ng-container>

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { UserFeedbackModalComponent } from './user-feedback-modal/user-feedback-modal.component';

@Component({
    selector: 'app-user-feedback',
    templateUrl: './user-feedback.component.html',
    styleUrls: ['./user-feedback.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserFeedbackComponent {
    @Input() userFeedbackModal: UserFeedbackModalComponent;

    openModal() {
        this.userFeedbackModal.open();
    }
}

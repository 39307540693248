import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({ name: 'luxonFormatWithOrdinals' })
export class LuxonFormatWithOrdinalsPipe implements PipeTransform {
    transform(value: DateTime, format: string) {
        if (!value) {
            return '';
        }

        const pr = new Intl.PluralRules('en-US', {
            type: 'ordinal',
        });
        const suffixes = new Map([
            ['one', 'st'],
            ['two', 'nd'],
            ['few', 'rd'],
            ['other', 'th'],
        ]);
        const formatOrdinals = (n) => {
            const rule = pr.select(n);
            const suffix = suffixes.get(rule);
            return `${n}${suffix}`;
        };

        const wholeString = value.toFormat(format).slice();
        const splittedString = wholeString.split(' ');
        const daysWithOrdinals = formatOrdinals(splittedString[0]);
        splittedString.splice(0, 1);
        const rest = splittedString.join(' ');

        return `${daysWithOrdinals} ${rest}`;
    }
}

import { whitespaceTrimmer } from './../../../whitespace-trimmer';
import { NgControl } from '@angular/forms';
import { Directive, HostListener, Input } from '@angular/core';

@Directive({
    selector: '[appWhitespaceTrimmer][isWhitespaceTrimmerActivated]',
})
export class WhitespaceTrimmerDirective {
    @HostListener('focusout', ['$event']) applyWhitespaceTrimming(event: FocusEvent) {
        if (this.isWhitespaceTrimmerActivated) {
            this.control.control.patchValue(whitespaceTrimmer((event.target as HTMLInputElement).value));
        }
    }

    @Input() isWhitespaceTrimmerActivated;

    constructor(private control: NgControl) {}
}

<div
    bsModal
    #sharedModalComponent="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="sharedModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static', keyboard: false }"
>
    <div class="modal-dialog modal-lg"
         [ngClass]='modalCssClass'

    >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <ng-content select="[modalTitle]"></ng-content>
                </h4>
            </div>

            <div class="modal-body">
                <ng-content select="[modalBody]"></ng-content>
            </div>

            <div class="modal-footer">
                <ng-content select="[modalFooter]"></ng-content>
            </div>
        </div>
    </div>
</div>

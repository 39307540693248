import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarInstancesService } from '../sidebar-instances.service';
import { SidebarInstanceElement } from '../models/classes/sidebar-instance-element.class';
import { SidebarInstance } from '../models/classes/sidebar-instance.abstract-class';

@Component({
    selector: 'app-sidebar-instance-single',
    templateUrl: './sidebar-instance-single.component.html',
    styleUrls: ['./sidebar-instance-single.component.scss'],
})
export class SidebarInstanceSingleComponent extends SidebarInstance implements OnInit {
    @Input() item: SidebarInstanceElement;

    constructor(injector: Injector, public router: Router, public sidebarInstancesService: SidebarInstancesService) {
        super(injector);
    }
    ngOnInit() {
        this.activatedRoute = this.router.url;

        this.routerEventsSub = this.onNavigationEnd();
    }

    ngOnDestroy() {
        this.routerEventsSub.unsubscribe();
    }
}

<app-shared-modal #sharedModal>
    <span modalTitle>
        <i class="fa-regular fa-pen-to-square mr-3"></i>

        Share your feedback with us
    </span>

    <ng-container modalBody *ngIf="sharedModal.active">
        <div appFocusOnFirstInput>
            <p>Do you have any suggestions or have any problems? Let us know by providing your feedback.</p>

            <div class="form-group">
                <app-textarea-input
                    [attr.data-e2e-hook]="'description-textarea'"
                    [control]="descriptionControl"
                    [label]="'Description'"
                    [charactersLimit]="999"
                ></app-textarea-input>
            </div>
        </div>
    </ng-container>

    <ng-container modalFooter>
        <button
            [disabled]="sharedModal.saving"
            [attr.data-e2e-hook]="'cancel-button'"
            type="button"
            class="btn btn-csc-base btn-csc-base--main-transparent text-14px text-csc-hard-grey"
            (click)="closeAndResetFormValues()"
        >
            Cancel
        </button>

        <button
            type="button"
            class="btn btn-csc-base text-14px"
            (click)="send()"
            [attr.data-e2e-hook]="'submit-button'"
            [disabled]="sharedModal.saving || descriptionControl.invalid || descriptionControl.untouched"
            [buttonBusy]="sharedModal.saving"
            [busyText]="'Sending...'"
        >
            Send
        </button>
    </ng-container>
</app-shared-modal>

<div [@routerTransition]>
    <div class="content d-flex flex-column flex-column-fluid">
        <sub-header [title]="'404'"></sub-header>

        <div [class]="containerClass">
            <div class="card card-custom gutter-b">
                <div class="card-body">
                    <div class="d-flex flex-column justify-content-md-center">
                        <h1 class="font-weight-boldest text-dark-75">How did you get here?</h1>
                        <hr />
                        <p class="font-size-h4 line-height-md">
                            Sorry we can't seem to find the page you're looking for.
                        </p>
                        <p class="font-size-h4 line-height-md">
                            There may be a misspelling in the URL entered, or the page you are looking for may no longer
                            exist.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

/**
 * Some endpoints are expecting large amount of arguments.
 * To prevent long, repetitive lists of arguments one of methods
 * can be used.
 */
export class ApiRequestArguments {
    static get defaultExperimentDesignsGetAll(): [undefined, undefined, undefined, undefined, 0, 1000] {
        const filter = undefined;
        const nameFilter = undefined;
        const valueFilter = undefined;
        const sorting = undefined;
        const skipCount = 0;
        const maxResultCount = 1000;

        return [filter, nameFilter, valueFilter, sorting, skipCount, maxResultCount];
    }

    static get defaultProjectsGetAll(): [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        0,
        1000
    ] {
        const filter = undefined;
        const nameFilter = undefined;
        const descriptionFilter = undefined;
        const maxStartDateFilter = undefined;
        const minStartDateFilter = undefined;
        const maxEndDateFilter = undefined;
        const minEndDateFilter = undefined;
        const experimentDesignNameFilter = undefined;
        const sorting = undefined;
        const skipCount = 0;
        const maxResultCount = 1000;

        return [
            filter,
            nameFilter,
            descriptionFilter,
            maxStartDateFilter,
            minStartDateFilter,
            maxEndDateFilter,
            minEndDateFilter,
            experimentDesignNameFilter,
            sorting,
            skipCount,
            maxResultCount,
        ];
    }

    static get defaultDataVisualizationsGetAll(): [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        0,
        1000
    ] {
        const filter = undefined;
        const nameFilter = undefined;
        const descriptionFilter = undefined;
        const isActiveFilter = undefined;
        const locationFilter = undefined;
        const parametersFilter = undefined;
        const typeFilter = undefined;
        const sorting = undefined;
        const skipCount = 0;
        const maxResultCount = 1000;

        return [
            filter,
            nameFilter,
            descriptionFilter,
            isActiveFilter,
            locationFilter,
            parametersFilter,
            typeFilter,
            sorting,
            skipCount,
            maxResultCount,
        ];
    }
}

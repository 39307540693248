import { SchemaValidator } from './../../../../../shared/helpers/SchemaValidator';
import { DataVisualizationService } from '@app/main/data-visualization/data-visualization.service';
import { ProjectRelatedNavigation } from './../project-related-navigation/project-related-navigation';
import { Injectable } from '@angular/core';
import { ExperimentDesignDto, ProjectDto } from '@shared/service-proxies/service-proxies';
import { SidebarInstancesTypes } from './models/enums/sidebar-instances-types.enum';
import { SidebarInstanceElement } from './models/classes/sidebar-instance-element.class';
import { PermissionsAndFeaturesResolverService } from '@shared/permissions-and-features-resolver/permissions-and-features-resolver.service';

@Injectable({ providedIn: 'root' })
export class SidebarInstancesFactory {
    constructor(
        private dataVisualizationService: DataVisualizationService,
        private permissionsAndFeaturesResolverService: PermissionsAndFeaturesResolverService
    ) {}

    create(type: SidebarInstancesTypes, data: ProjectDto | ExperimentDesignDto): SidebarInstanceElement {
        switch (type) {
            case SidebarInstancesTypes.project:
                return this.createSidebarInstanceOfProject(data as ProjectDto);
                break;
            case SidebarInstancesTypes.experimentDesign:
                return this.createSidebarInstanceOfExperimentDesign(data as ExperimentDesignDto);
                break;
        }
    }

    /**
     * Tries to create `SidebarInstanceElement` from its deserialized form.
     */
    recreate(instance: Partial<SidebarInstanceElement>): SidebarInstanceElement | undefined {
        const isSchemaValid = SchemaValidator.validate(instance, {
            isOpened: 'Boolean',
            name: 'String',
            type: 'String',
            id: 'String',
            items: 'Array',
            route: 'Array',
        });

        if (!isSchemaValid) {
            return;
        }

        if (instance.type === SidebarInstancesTypes.experimentDesign) {
            return new SidebarInstanceElement(
                instance.name,
                instance.type,
                instance.id,
                instance.items,
                instance.route,
                instance.isOpened,
                this.permissionsAndFeaturesResolverService.isAllowed('experimentDesign.canRead')
            );
        }

        if (instance.type === SidebarInstancesTypes.project) {
            return new SidebarInstanceElement(
                instance.name,
                instance.type,
                instance.id,
                instance.items,
                instance.route,
                instance.isOpened
            );
        }
    }

    private createSidebarInstanceOfProject(project: ProjectDto): SidebarInstanceElement {
        const projectRelatedNavigation = new ProjectRelatedNavigation(
            project,
            !!this.dataVisualizationService.firstActiveDataVisualization,
            this.permissionsAndFeaturesResolverService
        );

        return new SidebarInstanceElement(
            project.name,
            SidebarInstancesTypes.project,
            project.id,
            projectRelatedNavigation.items,
            [],
            true
        );
    }

    private createSidebarInstanceOfExperimentDesign(experimentDesign: ExperimentDesignDto): SidebarInstanceElement {
        return new SidebarInstanceElement(
            experimentDesign.name,
            SidebarInstancesTypes.experimentDesign,
            experimentDesign.id,
            [],
            ['/app/main/experiment-designs-overview', experimentDesign.id],
            false,
            this.permissionsAndFeaturesResolverService.isAllowed('experimentDesign.canRead')
        );
    }
}

import { Component, Input } from '@angular/core';
import { BannerDto, BannerType } from '@shared/service-proxies/service-proxies';

@Component({
    selector: 'app-csc-alert',
    templateUrl: './csc-alert.component.html',
    styleUrls: ['./csc-alert.component.scss'],
})
export class CscAlertComponent {
    @Input() bannerSettings: BannerDto;

    bannerTypes = BannerType;
}

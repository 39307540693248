import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';
import { ProjectPageService } from '../project-page/project-page.service';

/**
 * An service to store explicit information about projects. If you would like to store information about certain project, {@link ProjectPageService} might suit better.
 * Notice: If there will be more properties and methods than 2, consider using some general function instead explicit methods to manage state.
 */
@Injectable({ providedIn: 'root' })
export class ProjectsRelatedStore {
    /**
     * Contain id's of projects that requested download all files but the request hasn't been resolved yet
     */
    private idsOfProjectsProcessingDownloadAllZpts: Set<string> = new Set();
    private _idsOfProjectsProcessingDownloadAllZpts$ = new BehaviorSubject<Set<string>>(
        this.idsOfProjectsProcessingDownloadAllZpts
    );
    idsOfProjectsProcessingDownloadAllZpts$ = this._idsOfProjectsProcessingDownloadAllZpts$.asObservable();
    addIdOfProjectProcessingDownloadAllZpts(id: string) {
        this.idsOfProjectsProcessingDownloadAllZpts.add(id);
        this._idsOfProjectsProcessingDownloadAllZpts$.next(this.idsOfProjectsProcessingDownloadAllZpts);
    }
    deleteIdOfProjectProcessingDownloadAllZpts(id: string) {
        this.idsOfProjectsProcessingDownloadAllZpts.delete(id);
        this._idsOfProjectsProcessingDownloadAllZpts$.next(this.idsOfProjectsProcessingDownloadAllZpts);
    }

    /**
     * Contain id's of projects that requested export transient analysis results but the request hasn't been resolved yet
     */
    private idsOfProjectsProcessingExportTars: Set<string> = new Set();
    private _idsOfProjectsProcessingExportTars$ = new BehaviorSubject<Set<string>>(
        this.idsOfProjectsProcessingExportTars
    );
    idsOfProjectsProcessingExportTars$ = this._idsOfProjectsProcessingExportTars$.asObservable();
    addIdOfProjectsProcessingExportTars(id: string) {
        this.idsOfProjectsProcessingExportTars.add(id);
        this._idsOfProjectsProcessingExportTars$.next(this.idsOfProjectsProcessingExportTars);
    }
    deleteIdOfProjectsProcessingExportTars(id: string) {
        this.idsOfProjectsProcessingExportTars.delete(id);
        this._idsOfProjectsProcessingExportTars$.next(this.idsOfProjectsProcessingExportTars);
    }
}

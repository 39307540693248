<a
    class="primary-sidebar-instance"
    [attr.data-e2e-hook]="'sidebar-experiment-design-instance-' + item.name"
    (click)="item.isEnabled ? navigate(item.route) : null"
    [ngClass]="{
        'primary-sidebar-instance--active': activatedRouteMatchesHref(item.route[1]),
        disabled: !item.isEnabled
    }"
>
    <div class="primary-sidebar-instance__heading">
        <span class="primary-sidebar-instance__heading-icon-wrapper">
            <i [attr.class]="getInstanceMainIcon(item.type)"></i>
        </span>

        <div class="d-flex flex-column primary-sidebar-instance__heading-text-wrapper">
            <h3 class="primary-sidebar-instance__name-text truncate-long-text" [title]="item.name">{{ item.name }}</h3>
            <h5 class="primary-sidebar-instance__type-text">{{ item.type }}</h5>
        </div>

        <span class="primary-sidebar-instance__heading-cross-icon-wrapper">
            <i class="fa fa-xmark-large text-16px" (click)="onItemDelete()"></i>
        </span>
    </div>
</a>

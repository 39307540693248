<label class="form-field__label-wrapper" [for]="label">
    <span class="text-12px text-csc-hard-grey">
        {{ label }}
        <span *ngIf="hasRequiredAsterisk" class="text-danger">*</span>
    </span>

    <validation-messages
        *ngIf="hasValidationMessage && control.touched"
        [formCtrl]="control"
        [errorDefs]="errorDefs"
    ></validation-messages>
</label>

<div
    bsPopover
    [popover]="popoverText"
    [triggers]="'none'"
    [isOpen]="isPopoverVisible"
    [placement]="'bottom left'"
    [containerClass]="'csc-primary-popover'"
>
    <input
        type="text"
        class="form-field__input-element form-control"
        autoFocus
        appWhitespaceTrimmer
        [isWhitespaceTrimmerActivated]="isWhitespaceTrimmerUsed"
        [placeholder]="placeholder"
        [id]="label"
        [formControl]="control"
    />
</div>

<div class="text-right text-black-25 text-10px mt-1">
    {{ description }}
</div>

import { SharedInputPropertiesDirective } from './../shared-input-properties.directive';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
    selector: 'app-textarea-input',
    templateUrl: './textarea-input.component.html',
    styleUrls: ['./../shared-input-styles.scss', './textarea-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextareaInputComponent extends SharedInputPropertiesDirective {
    @Input() charactersLimit: number;
}

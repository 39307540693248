<label class="form-field__label-wrapper" [for]="label">
    <span class="text-12px text-csc-hard-grey">
        {{ label }}
        <span *ngIf="hasRequiredAsterisk" class="text-danger">*</span>
    </span>

    <validation-messages
        [errorDefs]="dateInputErrorDefs"
        *ngIf="hasValidationMessage && control.touched"
        [formCtrl]="control"
    ></validation-messages>
</label>

<div class="d-flex">
    <input
        id="startDateInput"
        class="form-control form-field__input-element form-field__input-element--datepicker"
        name="StartDate"
        bsDatepicker
        #datepicker="bsDatepicker"
        type="datetime"
        [formControl]="control"
        [minDate]="minStartDate"
        [triggers]="'none'"
    />

    <button
        type="button"
        class="btn btn-csc-base btn-csc-base--main-transparent form-field__datepicker-button"
        (click)="datepicker.show()"
    >
        <i class="fa-light fa-calendar-lines p-0 text-csc-merchant-marine text-20px"></i>
    </button>
</div>

<div class="text-right text-black-25 text-10px mt-1">
    {{ description }}
</div>

import { Injectable } from '@angular/core';
import { FeatureCheckerService, PermissionCheckerService } from 'abp-ng2-module';
import { get as _get } from 'lodash-es';

export type CytoSolverAvailableActionsPath =
    `${keyof CytoSolverAvailableActions}.${keyof CytoSolverAvailableActions[keyof CytoSolverAvailableActions]}`;

// missing: zpt files crud (to verify), data annotation (to verify), DV (to verify)
// At least 1 key has to be the same in first level
export interface CytoSolverAvailableActions {
    projects: {
        canCreate: boolean;
        canRead: boolean;
        canUpdate: boolean;
        canDelete: boolean;
        canExportExperiments: boolean;
        canAddZpts: boolean;
        canDownloadZpts: boolean;
        canUpdateCriteriaSettings: boolean;
        canSwitchDesign: boolean;
        canDetachDesign: boolean;
    };
    experimentDesign: {
        canCreate: boolean;
        canRead: boolean;
        canUpdate: boolean;
    };
    dataAnnotation: {
        canRead: boolean;
        canUpdate: boolean;
        clearAll: boolean;
    };
    zpts: {
        canRead: boolean;
        canUpdate: boolean;
        canDelete: boolean;
    };
    transientAnalysis: {
        canRead: boolean;
        canUpdate: boolean;
    };
    dataVisualization: {
        canRead: boolean;
        canUpdate: boolean;
    };
}

@Injectable({ providedIn: 'root' })
export class PermissionsAndFeaturesResolverService {
    actionsAllowed: CytoSolverAvailableActions;

    constructor(private permission: PermissionCheckerService, private feature: FeatureCheckerService) {}

    init() {
        this.actionsAllowed = {
            projects: {
                canRead: this.permission.isGranted('Pages.Projects'),
                canCreate:
                    this.permission.isGranted('Pages.Projects.Create') &&
                    this.permission.isGranted('Pages.ExperimentDesigns'),
                canDelete: this.permission.isGranted('Pages.Projects.Delete'),
                canUpdate:
                    this.permission.isGranted('Pages.Projects.Edit') &&
                    this.permission.isGranted('Pages.ExperimentDesigns'),
                canExportExperiments: this.permission.isGranted('Pages.TransientAnalysisResults'),
                canAddZpts:
                    this.permission.isGranted('Pages.Projects.Edit') &&
                    this.permission.isGranted('Pages.ZonePlotTraceFiles.Create'),
                canDownloadZpts: this.permission.isGranted('Pages.ZonePlotTraceFiles'),
                canUpdateCriteriaSettings: this.permission.isGranted('Pages.Projects.Edit'),
                canSwitchDesign:
                    this.permission.isGranted('Pages.DataAnnotation.Edit') &&
                    this.permission.isGranted('Pages.TransientAnalysisResults') &&
                    this.permission.isGranted('Pages.Projects.Edit') &&
                    this.permission.isGranted('Pages.ExperimentDesigns'),
                canDetachDesign:
                    this.permission.isGranted('Pages.DataAnnotation.Edit') &&
                    this.permission.isGranted('Pages.TransientAnalysisResults') &&
                    this.permission.isGranted('Pages.Projects.Edit'),
            },
            experimentDesign: {
                canRead: this.permission.isGranted('Pages.ExperimentDesigns'),
                canCreate: this.permission.isGranted('Pages.ExperimentDesigns.Create'),
                canUpdate: this.permission.isGranted('Pages.ExperimentDesigns.Edit'),
            },
            dataAnnotation: {
                canRead:
                    this.permission.isGranted('Pages.DataAnnotation') &&
                    this.permission.isGranted('Pages.ExperimentDesigns') &&
                    this.permission.isGranted('Pages.TransientAnalysisResults'),
                canUpdate:
                    this.permission.isGranted('Pages.DataAnnotation') &&
                    this.permission.isGranted('Pages.ExperimentDesigns') &&
                    this.permission.isGranted('Pages.TransientAnalysisResults'),
                clearAll:
                    this.permission.isGranted('Pages.DataAnnotation.Edit') &&
                    this.permission.isGranted('Pages.TransientAnalysisResults'),
            },
            zpts: {
                canRead: this.permission.isGranted('Pages.ZonePlotTraceFiles'),
                canUpdate: true,
                canDelete: this.permission.isGranted('Pages.ZonePlotTraceFiles.Delete'),
            },
            transientAnalysis: {
                canRead: this.permission.isGranted('Pages.TransientAnalysisResults'),
                canUpdate: this.permission.isGranted('Pages.TransientAnalysisResults.Edit'),
            },
            dataVisualization: {
                canRead: this.permission.isGranted('Pages.DataVisualization'),
                canUpdate: true, // Used only to satisfy some mapped type
            },
        };

        if (this.feature.isEnabled('App.ReadonlyState')) {
            this.actionsAllowed.projects.canCreate = false;
            this.actionsAllowed.projects.canDelete = false;
            this.actionsAllowed.projects.canUpdate = false;
            this.actionsAllowed.projects.canAddZpts = false;
            this.actionsAllowed.projects.canUpdateCriteriaSettings = false;
            this.actionsAllowed.projects.canSwitchDesign = false;
            this.actionsAllowed.projects.canDetachDesign = false;

            this.actionsAllowed.experimentDesign.canCreate = false;
            this.actionsAllowed.experimentDesign.canUpdate = false;

            this.actionsAllowed.dataAnnotation.clearAll = false;
            this.actionsAllowed.dataAnnotation.canUpdate = false;

            this.actionsAllowed.zpts.canDelete = false;
            this.actionsAllowed.zpts.canUpdate = false;

            this.actionsAllowed.transientAnalysis.canUpdate = false;

            this.actionsAllowed.dataVisualization.canUpdate = false;
        }
    }

    isAllowed(path: CytoSolverAvailableActionsPath): boolean {
        return _get(this.actionsAllowed, path);
    }
}

import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer) {}
    transform(v, type: 'url' | 'html') {
        if (type === 'url') {
            return this.sanitizer.bypassSecurityTrustResourceUrl(v);
        }

        return this.sanitizer.bypassSecurityTrustHtml(v);
    }
}

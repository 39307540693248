import { NgModule } from '@angular/core';
import { DateInputComponent } from './date-input/date-input.component';
import { SelectInputComponent } from './select-input/select-input.component';
import { TextInputComponent } from './text-input/text-input.component';
import { TextareaInputComponent } from './textarea-input/textarea-input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { UtilsModule } from '@shared/utils/utils.module';
import { WhiteSpaceTrimmerModule } from './directives/whitespace-trimmer.module';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { CytoSolverCommonModule } from '@shared/common/common.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        UtilsModule,
        WhiteSpaceTrimmerModule,
        PopoverModule.forRoot(),
        TooltipModule.forRoot(),
        BsDatepickerModule.forRoot(),
        CytoSolverCommonModule,
    ],
    declarations: [DateInputComponent, SelectInputComponent, TextInputComponent, TextareaInputComponent],
    exports: [DateInputComponent, SelectInputComponent, TextInputComponent, TextareaInputComponent],
})
export class SharedFormComponents {}

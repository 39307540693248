<a routerLink="/" target="_blank" class="mr-20">
    <img
        *ngIf="!appSession.tenant || !appSession.tenant.logoId"
        src="/assets/common/images/app-logo-on-dark.svg"
        alt="logo"
        height="38"
        class="max-h-35px"
    />
    <img
        *ngIf="appSession.tenant && appSession.tenant.logoId"
        [src]="
            remoteServiceBaseUrl +
            '/TenantCustomization/GetTenantLogo?skin=' +
            currentTheme.baseSettings.menu.asideSkin +
            '&tenantId=' +
            appSession.tenant.id +
            '&id=' +
            appSession.tenant.logoId
        "
        alt="logo"
        height="38"
    />
</a>

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'translateTraceType',
})
export class TranslateTraceTypePipe implements PipeTransform {
    transform(value: string, variant: 'normal' | 'short' = 'normal'): string {
        return this.getTranslation(value, variant) ?? value;
    }

    private getTranslation(value: string, variant: 'normal' | 'short') {
        switch (value) {
            case 'RatioMetricCalcium': {
                return variant === 'normal' ? 'Ratiometric Fluorescence' : 'RMF';
            }
            case 'SarcomereLength': {
                return variant === 'normal' ? 'Sarcomere Length' : 'SL';
            }
            case 'SingleWaveLength': {
                return variant === 'normal' ? 'Single Wave Length Fluorescence' : 'SWF';
            }
            case 'EdgeLength': {
                return variant === 'normal' ? 'Cell Length' : 'CL';
            }
            case 'PixelIntensity': {
                return variant === 'normal' ? 'Pixel Intensity' : 'PI';
            }
            case 'PixelCorrelation': {
                return variant === 'normal' ? 'Pixel Correlation' : 'PC';
            }
            case 'Cs1': {
                return variant === 'normal' ? 'Cs1' : 'PC';
            }
            case 'Cs2': {
                return variant === 'normal' ? 'Cs2' : 'PC';
            }
            case 'Cs3': {
                return variant === 'normal' ? 'Cs3' : 'PC';
            }
            case 'Cs4': {
                return variant === 'normal' ? 'Cs4' : 'PC';
            }
        }
    }
}

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class PersistingDataStorageService {
    private _projectsNameFilter$ = new BehaviorSubject('');
    projectsNameFilter$ = this._projectsNameFilter$.asObservable();
    updateProjectsNameFilter(v: string) {
        this._projectsNameFilter$.next(v);
    }

    private _experimentDesignsNameFilter$ = new BehaviorSubject('');
    experimentDesignsNameFilter$ = this._experimentDesignsNameFilter$.asObservable();
    updateExperimentDesignsNameFilter(v: string) {
        this._experimentDesignsNameFilter$.next(v);
    }

    private _hasUnsavedChangesInExperimentDesigner$ = new BehaviorSubject(false);
    hasUnsavedChangesInExperimentDesigner$ = this._hasUnsavedChangesInExperimentDesigner$.asObservable();
    updateHasUnsavedChangesInExperimentDesigner(v: boolean) {
        this._hasUnsavedChangesInExperimentDesigner$.next(v);
    }
}

import { Injectable } from '@angular/core';
import { AbpHttpConfigurationService, IErrorInfo, LogService, MessageService } from 'abp-ng2-module';
import { Router } from '@angular/router';

@Injectable({
    providedIn: 'root',
})
export class ZeroTemplateHttpConfigurationService extends AbpHttpConfigurationService {
    constructor(messageService: MessageService, logService: LogService, private _route: Router) {
        super(messageService, logService);
    }

    showError(error: IErrorInfo) {
        const ommitedErrors = [
            'Conflict: Project of the same name already exists.',
            'Conflict: Experiment design of the same name already exists.',
            'Required permissions are not granted. At least one of these permissions must be granted: Experiment designs',
            'Required permissions are not granted. At least one of these permissions must be granted: Transient analysis results',
        ];

        if (ommitedErrors.includes(error?.message)) {
            return;
        }

        super.showError(error);
    }

    // Override handleUnAuthorizedRequest so it doesn't refresh the page during failed login attempts.
    handleUnAuthorizedRequest(messagePromise: any, targetUrl?: string) {
        if (this._route.url === '/account/login') {
            return;
        }

        const self = this;

        if (messagePromise) {
            messagePromise.done(() => {
                this.handleTargetUrl(targetUrl || '/');
            });
        } else {
            self.handleTargetUrl(targetUrl || '/');
        }
    }
}

<div
    class="primary-sidebar-instance"
    [attr.data-e2e-hook]="'sidebar-project-instance-' + item.name"
    [ngClass]="{ 'primary-sidebar-instance--active': item.isOpened && item?.items?.length }"
>
    <div class="primary-sidebar-instance__heading" (click)="toggleOpen()">
        <span class="primary-sidebar-instance__heading-icon-wrapper">
            <i [attr.class]="getInstanceMainIcon(item.type)"></i>
        </span>

        <div class="d-flex flex-column primary-sidebar-instance__heading-text-wrapper">
            <h3 class="primary-sidebar-instance__name-text truncate-long-text" [title]="item.name">{{ item.name }}</h3>
            <h5 class="primary-sidebar-instance__type-text">{{ item.type }}</h5>
        </div>

        <span class="primary-sidebar-instance__heading-cross-icon-wrapper">
            <i class="fa fa-xmark-large text-16px" (click)="onItemDelete()"></i>
        </span>

        <span class="primary-sidebar-instance__project-first-letter">{{ item.name.slice(0, 1) }}</span>
    </div>

    <ul class="primary-sidebar-instance__list" [hidden]="shouldHideSubitems">
        <li *ngFor="let subitem of item.items">
            <a
                (click)="subitem.isEnabled ? navigate(subitem.route) : null"
                [ngClass]="{ active: activatedRouteMatchesSubitem(subitem.route), disabled: !subitem.isEnabled }"
            >
                <i class="icon {{ subitem.icon }}"></i>
                <span class="text">{{ subitem.name }}</span>
            </a>
        </li>
    </ul>
</div>

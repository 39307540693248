import { InstancesPositionsUnion } from '../sidebar-instance/models/types/sidebar-instances-positions.type';

export class NavigationalElement {
    constructor(
        public name: InstancesPositionsUnion,
        public icon: string,
        public route: string[],
        public isEnabled = true
    ) {}
}

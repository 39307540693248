<label class="form-field__label-wrapper" [for]="label">
    <span class="text-12px text-csc-hard-grey d-inline-flex align-items-center">
        <span [innerHtml]="iconHtml | safe: 'html'"></span>
        {{ label }}
        <span *ngIf="hasRequiredAsterisk" class="text-danger">*</span>

        <i
            *ngIf="tooltipText"
            class="far fa-question-circle csc-select-input-tooltip"
            container="body"
            containerClass="tooltip-csc-primary"
            [tooltip]="tooltipText"
        ></i>
    </span>

    <validation-messages *ngIf="hasValidationMessage && control.touched" [formCtrl]="control"></validation-messages>
</label>

<select
    [id]="label"
    class="form-control custom-chevron form-field__input-element form-field__input-element--select"
    [ngClass]="{ 'has-default-option-selected': hasDefaultValueSelected }"
    [formControl]="control"
>
    <option *ngIf="showEmptyValue" [disabled]="isNullValueBlocked" selected [ngValue]="null">
        {{ emptyValueLabel }}
    </option>
    <option *ngFor="let item of availableOptions" [ngValue]="item.value">
        {{ item.label }}
    </option>
</select>

<div *ngIf="description" class="text-right text-black-25 text-10px mt-1">
    {{ description }}
</div>

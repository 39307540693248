import { Component, NgZone, OnInit } from '@angular/core';
import { NgxSpinnerTextService } from '@app/shared/ngx-spinner-text.service';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ApplicationInsightsService } from '@shared/application-insights/application-insights.service';
import { environment } from './environments/environment';

@Component({
    selector: 'app-root',
    template: `
        <router-outlet></router-outlet>
        <ngx-spinner type="ball-clip-rotate" size="medium" color="#5ba7ea">
            <p *ngIf="ngxSpinnerText">{{ getSpinnerText() }}</p>
        </ngx-spinner>
    `,
})
export class RootComponent implements OnInit {
    ngxSpinnerText: NgxSpinnerTextService;

    constructor(
        _ngxSpinnerText: NgxSpinnerTextService,
        private applicationInsights: ApplicationInsightsService,
        private ngZone: NgZone
    ) {
        this.ngxSpinnerText = _ngxSpinnerText;
    }

    ngOnInit() {
        if (environment.features.applicationInsights.isUsed) {
            this.runApplicationInsightsOutsideAngular();
        }
    }

    getSpinnerText(): string {
        return this.ngxSpinnerText.getText();
    }

    private runApplicationInsightsOutsideAngular() {
        this.ngZone.runOutsideAngular(() => {
            this.applicationInsights.appInsights = new ApplicationInsights({
                config: {
                    instrumentationKey: environment.features.applicationInsights.instrumentationKey,
                    disableTelemetry: !!navigator.webdriver,
                },
            });

            this.applicationInsights.appInsights.loadAppInsights();
            this.applicationInsights.loadCustomTelemetryProperties();
            this.applicationInsights.createRouterSubscription();
        });
    }
}

import { IEnvironment } from './environment.interface';

export const environment: IEnvironment = {
    production: false,
    hmr: false,
    localDevelopment: false,
    appConfig: 'appconfig.dev.json',
    remoteEnvironmentName: 'alpha',
    features: {
        applicationInsights: {
            isUsed: true,
            instrumentationKey: '1db74721-1ed5-4220-9f41-e5516edf3735',
        },
        dataVis: {
            usesCustomConfig: false,
        },
    },
    bootstrapSettings: (window as any).bootstrapSettings,
    variables: {
        ZPT_UPLOAD_DELAY: 1000,
    },
};

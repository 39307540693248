<ng-container
    *ngIf="{
        csdInstallerDownloadProgress: (mainModuleStoreService.csdInstallerDownloadProgress$ | async),
        userManualDownloadProgress: (mainModuleStoreService.userManualDownloadProgress$ | async)
    } as storeData"
>
    <div
        *ngIf="storeData.csdInstallerDownloadProgress !== null"
        class="d-inline-block w-60px mx-2"
        title="CSD Installer download progress"
    >
        <img
            class="img-fluid w-25px"
            src="./../../../../../assets/csc/icons/CSD-installer.png"
            alt="CSD installer icon"
        />
        <span class="text-dark-50">&nbsp; {{ storeData.csdInstallerDownloadProgress }}%</span>
    </div>

    <div
        *ngIf="storeData.userManualDownloadProgress !== null"
        class="d-inline-block w-60px"
        title="CytoSolver manual download progress"
    >
        <img
            class="img-fluid w-25px"
            src="./../../../../../assets/csc/icons/CytoSolver-manual.png"
            alt="CytoSolver manual icon"
        />
        <span class="text-dark-50">&nbsp; {{ storeData.userManualDownloadProgress }}%</span>
    </div>
</ng-container>

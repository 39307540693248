import { Component, Injector, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SidebarInstancesService } from '../sidebar-instances.service';
import { SidebarInstanceElement } from '../models/classes/sidebar-instance-element.class';
import { SidebarInstance } from '../models/classes/sidebar-instance.abstract-class';

@Component({
    selector: 'app-sidebar-instance-with-subelements',
    templateUrl: './sidebar-instance-with-subelements.component.html',
    styleUrls: ['./sidebar-instance-with-subelements.component.scss'],
})
export class SidebarInstanceWithSubelementsComponent extends SidebarInstance implements OnInit {
    @Input() item: SidebarInstanceElement;

    get shouldHideSubitems(): boolean {
        if (this.item.items) {
            return this.item.items.length && !this.item.isOpened;
        }

        return true;
    }

    constructor(injector: Injector, public router: Router, public sidebarInstancesService: SidebarInstancesService) {
        super(injector);
    }

    ngOnInit() {
        this.activatedRoute = this.router.url;

        this.routerEventsSub = this.onNavigationEnd();
    }

    ngOnDestroy() {
        this.routerEventsSub.unsubscribe();
    }
}

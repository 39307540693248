import { SharedInputPropertiesDirective } from './../shared-input-properties.directive';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-select-input',
    templateUrl: './select-input.component.html',
    styleUrls: ['./../shared-input-styles.scss', './select-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectInputComponent extends SharedInputPropertiesDirective implements OnInit {
    @Input() availableOptions: { label: string; value: string }[];
    @Input() emptyValueLabel = 'Choose from list';
    @Input() showEmptyValue = true;
    @Input() isNullValueBlocked = false;
    @Input() tooltipText = '';

    hasDefaultValueSelected: boolean;

    ngOnInit() {
        this.hasDefaultValueSelected = this.control?.value === null;

        this.control.valueChanges.subscribe((v) => {
            v === null || v === 'null' ? (this.hasDefaultValueSelected = true) : (this.hasDefaultValueSelected = false);
        });
    }
}

import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import Swal from 'sweetalert2';

@Injectable({
    providedIn: 'root',
})
export class ResponseInterceptorService implements HttpInterceptor {
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((e: HttpErrorResponse) => {
                // Cases other than 0 are commented, because ABP provided its own HTTPInterceptor which covers some of those cases
                // Couldn't find where it is anyway. Probably this one: AbpHttpInterceptor

                switch (e.status) {
                    case 0:
                        this.swalPopup(
                            'It seems like internet connection has been broken. Please check connection and try again later.'
                        );
                        break;
                    // case 403:
                    //     this.swalPopup('You do not have rights to do this action.');
                    //     break;
                    // case 404:
                    //     this.swalPopup('Action you are trying to do cannot be accomplished. Destination of request does not exist, please contact with support.');
                    //     break;
                    // case 500:
                    //     this.swalPopup('Internal server error occurred, please contact with support.');
                    //     break;
                    // default:
                    //     this.swalPopup(`Unknown error (${e.status}) occurred, please contact with support.`);
                }
                return throwError(e);
            })
        );
    }

    private swalPopup(message: string) {
        Swal.fire('Dear user', message, 'warning');
    }
}

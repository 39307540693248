<div
    bsModal
    #downloadModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="downloadModal"
    aria-hidden="true"
    [config]="{ backdrop: false }"
>
    <div class="modal-dialog modal-xxl" *ngIf="active">
        <div class="modal-content">
            <div class="modal-body">
                <div class="close-modal" (click)="hide()">
                    <i class="fa fa-xmark text-16px"></i>
                </div>

                <h1 class="heading">
                    Fast. Automatic.
                    <br />
                    <app-animated-text-with-gradient></app-animated-text-with-gradient>
                    batch data analysis.
                </h1>

                <p class="paragraph">
                    Analyze batches of data in seconds by simply adding files and letting CytoSolver take care of
                    automatic transient rejection, data averaging, and curve fitting. CytoSolver outputs key parameters
                    quantifying cardiomyocyte contractile and calcium transient kinetics as well as signal amplitude.
                </p>

                <div
                    class="mb-3 d-inline-flex align-items-end justify-content-center position-relative"
                    *ngIf="{
                        csdDownloadPermission: ('Pages.DesktopInstaller.Download' | permission),
                        csdDownloadFeature: ('App.DesktopAppFeature' | checkFeature)
                    } as permissions"
                >
                    <app-select-input
                        class='version-select'
                        [control]="installerVersion"
                        [label]="'Installer version'"
                        [iconHtml]="'<i class=\'fa-light fa-pen-ruler text-15px text-csc-x11-grey mr-2\'>'"
                        [availableOptions]="availableInstallers"
                        [emptyValueLabel]='emptyValueLabelForSelect'
                    ></app-select-input>

                    <ng-container *ngIf="!permissions.csdDownloadPermission || !permissions.csdDownloadFeature">
                        <span
                            class="d-inline-flex"
                            [tooltip]="
                                !permissions.csdDownloadPermission
                                    ? 'Permission for CytoSolver Desktop download not granted'
                                    : 'Your current CytoSolver subscription does not include the Desktop application, please contact your admin to upgrade your subscription'
                            "
                            container="body"
                            containerClass="tooltip-csc-primary"
                            triggers="pointerenter:pointerout"
                            placement="bottom"
                        >
                            <button
                                class="
                                    btn btn-csc-base btn-csc-base--main-transparent-with-primary-accent
                                    text-13px
                                    h-45px
                                    w-300px
                                "
                                disabled
                            >
                                <i class="fa-brands fa-windows mr-3"></i>
                                Download for Desktop
                            </button>
                        </span>
                    </ng-container>

                    <ng-container *ngIf="permissions.csdDownloadPermission && permissions.csdDownloadFeature">
                        <button
                            class="btn btn-csc-base btn-csc-base--main-transparent-with-primary-accent text-13px h-45px w-300px"
                            (click)="triggerFileDownload()"
                            [disabled]="isBusy || installerVersion.invalid"
                        >
                            <i class="fa-brands fa-windows mr-3"></i>
                            Download for Desktop
                        </button>
                    </ng-container>
                </div>

                <p class="small-text">Windows 10 (64-bit) or higher</p>

                <p class="alert alert-csc-blue d-flex align-items-center text-white p-5" *ngIf="isBusy">
                    The download of your CytoSolver Desktop application has started. You can safely close this screen
                    (do not close the web page) and track the progress in the top right corner of the page. As soon as
                    the progress reaches 100% your download should be ready for you to install.
                </p>
            </div>

            <div class="modal-decorations">
                <div class="transient-force-ribbon"></div>

                <div class="mockups">
                    <img
                        class="mockup mockup-0"
                        src="../../../../../assets/csc/images/csd-download-modal/project-overview.jpg"
                        alt=""
                    />

                    <img
                        class="mockup mockup-1"
                        src="../../../../../assets/csc/images/csd-download-modal/transient-analysis.jpg"
                        alt=""
                    />

                    <img
                        class="mockup mockup-2"
                        src="../../../../../assets/csc/images/csd-download-modal/project-overview.jpg"
                        alt=""
                    />
                </div>
            </div>
        </div>
    </div>
</div>

import { BsDatepickerConfig, BsDaterangepickerConfig, BsLocaleService } from 'ngx-bootstrap/datepicker';
import { NgxBootstrapLocaleMappingService } from 'assets/ngx-bootstrap/ngx-bootstrap-locale-mapping.service';
import { defineLocale } from 'ngx-bootstrap/chronos';
import { ThemeHelper } from '@app/shared/layout/themes/ThemeHelper';

export class NgxBootstrapDatePickerConfigService {
    private static shouldUseConfigurationLocale: boolean;

    static getDaterangepickerConfig(): BsDaterangepickerConfig {
        return Object.assign(new BsDaterangepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getDatepickerConfig(): BsDatepickerConfig {
        return Object.assign(new BsDatepickerConfig(), {
            containerClass: 'theme-' + NgxBootstrapDatePickerConfigService.getTheme()
        });
    }

    static getTheme(): string {
        return ThemeHelper.getTheme();
    }

    static getDatepickerLocale(): BsLocaleService {
        let localeService = new BsLocaleService();
        localeService.use(new NgxBootstrapLocaleMappingService().map(NgxBootstrapDatePickerConfigService.shouldUseConfigurationLocale ? abp.localization.currentLanguage.name : navigator.language || abp.localization.currentLanguage.name).toLowerCase());
        return localeService;
    }

    static registerNgxBootstrapDatePickerLocales(): Promise<boolean> {
        // Commented, because this value is taken from language selector - we've hidden it so it will always get default value ("en");
        // if (abp.localization.currentLanguage.name === 'en') {
        //     return Promise.resolve(true);
        // }

        let supportedLocale = new NgxBootstrapLocaleMappingService().map(navigator.language || abp.localization.currentLanguage.name).toLowerCase();
        let moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(navigator.language || abp.localization.currentLanguage.name);

        return new Promise<boolean>((resolve, reject) => {
            import(`ngx-bootstrap/chronos/esm5/i18n/${supportedLocale}.js`)
                .then(module => {
                    defineLocale(new NgxBootstrapLocaleMappingService().map(navigator.language) || abp.localization.currentLanguage.name.toLowerCase(), module[`${moduleLocaleName}Locale`]);
                    resolve(true);
                }).catch(() => {
                    console.warn('NgxBootstrap locale from navigator language not resolved! Importing from configuration');

                    NgxBootstrapDatePickerConfigService.shouldUseConfigurationLocale = true;
                    supportedLocale = new NgxBootstrapLocaleMappingService().map(abp.localization.currentLanguage.name).toLowerCase();
                    moduleLocaleName = new NgxBootstrapLocaleMappingService().getModuleName(abp.localization.currentLanguage.name);

                    import(`ngx-bootstrap/chronos/esm5/i18n/${supportedLocale}.js`).then(module => {
                        defineLocale(supportedLocale, module[`${moduleLocaleName}Locale`]);
                        resolve(true);
                    }, reject)
                });
        });
    }
}

<div
    bsModal
    #uploadFilesProgressModal="bs-modal"
    class="modal fade"
    tabindex="-1"
    role="dialog"
    aria-labelledby="uploadFilesProgressModal"
    aria-hidden="true"
    [config]="{ backdrop: 'static' }"
>
    <div class="modal-dialog modal-lg">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span>Upload progress</span>
                </h4>
                <button type="button" class="close" (click)="close()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body text-black">
                <div
                    *ngFor="let item of uploadStatus$ | async; last as isLast"
                    [ngClass]="{
                        'mb-5': !isLast
                    }"
                >
                    <strong>
                        {{ item.projectName }}
                    </strong>

                    <app-file-preview
                        *ngFor="let file of item.files; let i = index"
                        [file]="file"
                        [index]="i"
                        [hideDeleteButton]="true"
                    ></app-file-preview>
                </div>
            </div>

            <div class="modal-footer">
                <button *ngIf="!uploadCompleted" type="button" class="btn btn-csc-base text-14px" (click)="minimize()">
                    Minimize
                </button>
                <button
                    type="button"
                    class="btn btn-csc-base btn-csc-base--main-transparent text-csc-hard-grey text-14px"
                    (click)="close()"
                >
                    Close
                </button>
            </div>
        </div>
    </div>
</div>

import { BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

/**
 * This service tracks ZPT files that has been sent by the user whether to reanalysis, or to deletion.
 * The purpose of this service is to have an observable that allows to track ZPT's that are currently
 * "hanging" in between state, i.e. User decided to delete ZPT, but server didn't respond yet.
 */
@Injectable({
    providedIn: 'root',
})
export class ZPTFilesProcessed {
    private _listOfIds = new Set<string>();
    private _listOfIds$ = new BehaviorSubject<string[]>([]);
    listOfIds$ = this._listOfIds$.asObservable();

    add(id: string) {
        this._listOfIds.add(id);
        this._listOfIds$.next(Array.from(this._listOfIds.values()));
    }

    delete(id: string) {
        this._listOfIds.delete(id);
        this._listOfIds$.next(Array.from(this._listOfIds.values()));
    }
}

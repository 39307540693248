<div
    class="alert alert-custom alert-custom--application-wide fade show mb-0 w-100"
    role="alert"
    [ngClass]="{
        'alert-light-warning': +bannerSettings.type === +bannerTypes.Warning,
        'alert-light-primary': bannerSettings.type !== undefined && +bannerSettings.type === +bannerTypes.Information,
        'alert-light-danger': +bannerSettings.type === +bannerTypes.Maintenance
    }"
>
    <div class="alert-icon">
        <i
            [ngClass]="{
                'flaticon-warning':
                    bannerSettings.type !== undefined && +bannerSettings.type !== +bannerTypes.Information,
                'flaticon-information':
                    bannerSettings.type !== undefined && +bannerSettings.type === +bannerTypes.Information
            }"
        ></i>
    </div>
    <div class="alert-text" [innerHTML]="bannerSettings.text | safe: 'html'"></div>
</div>

<!-- BEGIN: Aside Menu -->
<div [class]="'aside-menu-wrapper aside-menu-' + currentTheme.baseSettings.menu.asideSkin" id="kt_aside_menu_wrapper">
    <!--
    Caution: for #asideMenu the (mouseEnter)="mouseEnter($event)" and (mouseleave)="mouseLeave($event)"
    have been removed because they heavily affected performance
    -->
    <div
        #asideMenu
        ktMenu
        [options]="menuOptions"
        id="kt_aside_menu"
        class="aside-menu scroll aside-menu--height-fix"
        [attr.data-menu-vertical]="1"
        [attr.data-menu-scroll]="ui.getIsMenuScrollable() ? 1 : 0"
        [attr.data-menu-dropdown]="ui.isSubmenuToggleDropdown() ? 1 : 0"
        [class]="ui.getLeftAsideClass()"
    >
        <div class="flex-shrink-0">
            <div [perfectScrollbar]="{ wheelPropagation: false }">
                <ul class="menu-nav">
                    <li
                        class="menu-item mb-5"
                        aria-haspopup="true"
                        data-ktmenu-submenu-toggle="hover"
                        *ngIf="currentTheme.baseSettings.menu.searchActive"
                    >
                        <menu-search-bar></menu-search-bar>
                    </li>
                    <ng-container [ngTemplateOutlet]="menuListTemplate"></ng-container>
                </ul>
            </div>
        </div>

        <ul class="primary-storage-top-divider" *ngIf="sidebarInstances.length"></ul>

        <div
            *ngIf="sidebarInstances.length"
            [perfectScrollbar]="{ wheelPropagation: false }"
            [ngStyle]="{ position: 'relative' }"
            appScrollContentToBeVisible
            [selector]="activeInstanceSelector"
            [trigger]="scrollContentToBeVisible$"
        >
            <ul class="primary-storage" cdkDropList (cdkDropListDropped)="reorderSidebarInstances($event)">
                <app-sidebar-instance
                    cdkDrag
                    *ngFor="let item of sidebarInstances"
                    [item]="item"
                    [attr.data-selector-helper]="item.id"
                ></app-sidebar-instance>
            </ul>
        </div>
    </div>
</div>
<!-- END: Aside Menu -->

<!-- </div> -->

<ng-template #menuListTemplate>
    <ng-container *ngFor="let child of menu.items">
        <ng-container
            *ngIf="showMenuItem(child)"
            [ngTemplateOutlet]="mMenuItem"
            [ngTemplateOutletContext]="{ item: child, parentItem: null }"
        ></ng-container>
    </ng-container>
</ng-template>

<ng-template #mMenuItem let-item="item" let-parentItem="parentItem">
    <li
        *ngIf="showMenuItem(item)"
        [attr.aria-haspopup]="true"
        [attr.data-menu-toggle]="getItemAttrSubmenuToggle(item)"
        [attr.data-ktmenu-submenu-mode]="item.mode"
        [attr.data-ktmenu-dropdown-toggle-class]="item['dropdown-toggle-class']"
        [ngClass]="getItemCssClasses(item)"
        data-placement="right"
    >
        <!-- if menu item hasn't submenu -->
        <a
            *ngIf="!item.items.length && !item.external"
            [queryParams]="item.parameters"
            [routerLink]="item.route"
            class="menu-link"
        >
            <ng-container
                [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
            ></ng-container>
        </a>
        <a
            *ngIf="!item.items.length && item.external"
            [attr.href]="item.route"
            target="_blank"
            class="menu-link menu-toggle"
        >
            <ng-container
                [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
            ></ng-container>
        </a>

        <!-- if menu item has submenu child  -->
        <a href="javascript:;" *ngIf="item.items.length" class="menu-link menu-toggle">
            <ng-container
                [ngTemplateOutlet]="mMenuItemText"
                [ngTemplateOutletContext]="{ item: item, parentItem: parentItem }"
            ></ng-container>
        </a>

        <!-- if menu item has submenu child then recursively call new menu item component -->
        <div *ngIf="item.items.length" class="menu-submenu" [style]="ui.getLeftAsideSubMenuStyles()">
            <span class="menu-arrow"></span>
            <ul class="menu-subnav">
                <ng-container *ngFor="let child of item.items">
                    <ng-container
                        [ngTemplateOutlet]="mMenuItem"
                        [ngTemplateOutletContext]="{ item: child, parentItem: item }"
                    ></ng-container>
                </ng-container>
            </ul>
        </div>
    </li>
</ng-template>

<ng-template #mMenuItemText let-item="item" let-parentItem="parentItem">
    <span class="menu-item-here"></span>
    <!-- if menu item has icon -->
    <span class="menu-icon" *ngIf="item.icon">
        <i class="menu-link-icon" [ngClass]="item.icon"></i>
    </span>

    <ng-container *ngIf="!item.badge; else mMenuLinkBadge">
        <!-- menu item title text -->
        <span class="menu-text">{{ item.name | localize }}</span>
    </ng-container>

    <ng-template #mMenuLinkBadge>
        <!-- menu item with badge -->
        <span class="menu-link-title">
            <span class="menu-link-wrap">
                <span class="menu-text">
                    {{ item.name | localize }}
                </span>
                <span class="menu-link-badge">
                    <span class="label label-primary" [ngClass]="item.badge.type">{{ item.badge.value }}</span>
                </span>
            </span>
        </span>
    </ng-template>

    <!-- if menu item has submenu child then put arrow icon -->
    <span *ngIf="item.items.length" class="menu-arrow"></span>
</ng-template>
<!-- END: Left Aside -->

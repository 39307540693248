<div
    class="floating-upload-widget"
    (click)="onWidgetClick()"
    [ngClass]="{ success: uploadCompleted && failedCount === 0, warning: uploadCompleted && failedCount !== 0 }"
>
    <span class="floating-upload-widget__text-placeholder" *ngIf="!uploadCompleted; else uploadCompletedTextRef">
        Uploading {{ filesCount }} ({{ uploadedCount }} completed) files for {{ projectsCount }} projects in progress.
    </span>

    <span [hidden]="uploadCompleted" class="file-uploading-icon floating-upload-widget__upload-icon">
        <i aria-hidden="true" class="fas fa-hdd"></i>
        <i aria-hidden="true" class="fas fa-arrow-up"></i>
    </span>

    <span class="floating-upload-widget__close-icon" (click)="onClose(); $event.stopPropagation()">
        <i aria-hidden="true" class="ki ki-close"></i>
    </span>
</div>

<ng-template #uploadCompletedTextRef>
    <span class="floating-upload-widget__text-placeholder" *ngIf="failedCount === 0">
        All files have been uploaded successfully. Click here to see the progress of the upload.
    </span>

    <span class="floating-upload-widget__text-placeholder" *ngIf="failedCount !== 0">
        Files uploading process have been completed, but some of the files were rejected by the server ({{
            failedCount
        }}). Click here to see the progress of the upload.
    </span>
</ng-template>

import { SidebarInstancesTypes } from './../enums/sidebar-instances-types.enum';
import { ChangeDetectorRef, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { SidebarInstanceElement } from './sidebar-instance-element.class';
import { SidebarInstancesService } from '../../sidebar-instances.service';
import memo from 'memo-decorator';
import { take } from 'rxjs/operators';
import { NavigateWithReRender } from '@app/main/shared/navigate-with-re-render';

export abstract class SidebarInstance {
    item: SidebarInstanceElement;
    activatedRoute = '';
    routerEventsSub: Subscription;
    router: Router;
    sidebarInstancesService: SidebarInstancesService;
    changeDetectorRef: ChangeDetectorRef;

    constructor(injector: Injector) {
        this.router = injector.get(Router);
        this.sidebarInstancesService = injector.get(SidebarInstancesService);
        this.changeDetectorRef = injector.get(ChangeDetectorRef);
    }

    onItemDelete() {
        this.sidebarInstancesService.deleteItem(this.item.id);

        this.navigateUserBackToListingIfHeClosedCurrentlyActiveInstance();
    }

    toggleOpen() {
        this.item.isOpened = !this.item.isOpened;

        this.sidebarInstancesService.items$.pipe(take(1)).subscribe((instances) => {
            const indexOfInstance = instances.indexOf(this.item);

            instances[indexOfInstance] = this.item;

            this.sidebarInstancesService.saveSidebarInstancesToLocalStorage();
            this.sidebarInstancesService.items$.next(instances);
        });
    }

    activatedRouteMatchesHref(url: string): boolean {
        return this.activatedRoute.includes(url);
    }

    activatedRouteMatchesSubitem(url: any[]): boolean {
        return this.activatedRoute === url.join('/');
    }

    navigate(route: string[]) {
        NavigateWithReRender.navigate(this.router, route);
    }

    @memo() // prevents function call on every change detection.
    getInstanceMainIcon(type: SidebarInstancesTypes) {
        switch (type) {
            case SidebarInstancesTypes.project:
                return 'fa-light fa-rectangle-history';
                break;
            case SidebarInstancesTypes.experimentDesign:
                return 'fa-light fa-pen-ruler';
                break;
            default:
                return 'flaticon-shapes';
        }
    }

    protected onNavigationEnd(): Subscription {
        return this.router.events.subscribe((data) => {
            if (data instanceof NavigationEnd) {
                this.activatedRoute = data.url;
                this.changeDetectorRef.markForCheck();
            }
        });
    }

    private navigateUserBackToListingIfHeClosedCurrentlyActiveInstance() {
        if (this.router.url.includes(this.item.id)) {
            const route =
                this.item.type === SidebarInstancesTypes.project
                    ? 'app/main/projects-overview'
                    : 'app/main/experiment-designs-overview';

            this.router.navigate([route]);
        }
    }
}

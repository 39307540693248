import { Component, Input } from '@angular/core';
import { SidebarInstancesTypes } from './models/enums/sidebar-instances-types.enum';
import { SidebarInstanceElement } from './models/classes/sidebar-instance-element.class';

@Component({
    selector: 'app-sidebar-instance',
    templateUrl: './sidebar-instance.component.html',
    styleUrls: ['./sidebar-instance.component.scss'],
})
export class SidebarInstanceComponent {
    @Input() item: SidebarInstanceElement;
    sidebarInstancesTypes = SidebarInstancesTypes;
}

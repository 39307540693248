import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Directive, Input, ChangeDetectorRef, OnInit, OnDestroy, Injector } from '@angular/core';
import { FormControl } from '@angular/forms';

@Directive({
    selector: '[appSharedInputProperties]',
})
export class SharedInputPropertiesDirective implements OnInit, OnDestroy {
    @Input() control: FormControl;

    @Input() iconHtml = '';
    @Input() label: string;
    @Input() description: string;
    @Input() placeholder = 'Empty placeholder...';
    @Input() hasRequiredAsterisk = false;
    @Input() hasValidationMessage = true;

    componentDestroyed$ = new Subject();
    cdRef: ChangeDetectorRef;

    constructor(injector: Injector) {
        this.cdRef = injector.get(ChangeDetectorRef);
    }

    ngOnInit() {
        this.control.valueChanges.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
            this.cdRef.detectChanges();
        });
    }

    ngOnDestroy() {
        this.componentDestroyed$.next();
    }
}

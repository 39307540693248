import { ProjectsServiceProxy, TraceType } from './../../../../shared/service-proxies/service-proxies';
import { Injectable } from '@angular/core';
import { ExperimentDesignDto, ProjectDto } from '@shared/service-proxies/service-proxies';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ProjectPageService {
    experimentDesignName: string;
    experimentDesign: ExperimentDesignDto;

    selectedProject: ProjectDto;

    /**
     * `null` value indicates no data, an initial state.
     *
     * `no_files` value indicates that ZPT files have been requested, but empty array was returned.
     *
     * `none_analyzed_yet` value indicates that ZPT files have been requested, but any of the files have proper state to
     * have TAR.
     */
    firstZPTFileIdFromZPTWidget$ = new BehaviorSubject<'no_files' | 'none_analyzed_yet' | string>(null);
    ZPTFilesUpdated$ = new Subject();

    private _hasZPTFiles$ = new BehaviorSubject<boolean>(false);
    hasZPTFiles$ = this._hasZPTFiles$.asObservable();

    /**
     * Sorted by creationTime
     */
    readonly projectsGetAll$ = this.projectsServiceProxy.getAll(
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        'creationTime',
        0,
        1000
    );

    constructor(private projectsServiceProxy: ProjectsServiceProxy) {}

    updateHasZPTFiles$(v: boolean) {
        this._hasZPTFiles$.next(v);
    }

    getTranslatedTransientDetectionTrace(): string {
        return TraceType[this.selectedProject.selectedTrace];
    }
}

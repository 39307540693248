import {
    Injectable,
    ComponentFactoryResolver,
    ApplicationRef,
    Injector,
    EmbeddedViewRef,
    ComponentRef,
} from '@angular/core';
import { catchStatementLogger } from '@shared/helpers/CatchStatementLogger';

@Injectable({
    providedIn: 'root',
})
export class DynamicComponentsService {
    private componentsRefs = new Map<any, ComponentRef<any>>();

    constructor(
        private componentFactoryResolver: ComponentFactoryResolver,
        private appRef: ApplicationRef,
        private injector: Injector
    ) {}

    appendComponentToBody(component: any) {
        this.componentsRefs.set(
            component,
            this.componentFactoryResolver.resolveComponentFactory(component).create(this.injector)
        );

        this.appRef.attachView(this.componentsRefs.get(component).hostView);

        const domElem = (this.componentsRefs.get(component).hostView as EmbeddedViewRef<any>)
            .rootNodes[0] as HTMLElement;

        document.body.appendChild(domElem);
    }

    destroyComponent(component) {
        const componentToDestroy = this.componentsRefs.get(component);

        if (!componentToDestroy) {
            return;
        }

        componentToDestroy.destroy();
        this.appRef.detachView(componentToDestroy.hostView);
        this.componentsRefs.delete(component);
    }
}

import { MainModuleStoreService } from './../main-module-store.service';
import { ProjectsRelatedStore } from './../projects/utils/projects-related-store.service';
import { PermissionCheckerService } from 'abp-ng2-module';
import { Injectable } from '@angular/core';
import { filter, map, take } from 'rxjs/operators';
import { FileUploadService } from '../projects/modals/upload-files/file-upload-wrapper/file-upload-wrapper.service';
import { PersistingDataStorageService } from './persisting-data-storage.service';
import { combineLatest } from 'rxjs';

/**
 * Use this service if you want to show native prompt when user tries to close application, but some action is still in progress.
 * @todo: It could be combined into single stream instead multiple different methods.
 */
@Injectable({
    providedIn: 'root',
})
export class PromptOnApplicationCloseService {
    constructor(
        private fileUploadService: FileUploadService,
        private persistingDataStorageService: PersistingDataStorageService,
        private permission: PermissionCheckerService,
        private projectsRelatedStore: ProjectsRelatedStore,
        private mainModuleStoreService: MainModuleStoreService
    ) {}

    /**
     * Will show native browser prompt if one of methods inside will evaluate to true. Prompt text is native: Do you want to leave this site? Changes you made may not be saved.
     */
    verify(e: BeforeUnloadEvent) {
        this.promptIfUploadIsInProgress(e);
        this.promptIfExperimentDesignerIsInvalid(e);
        this.promptIfDownloadAllFilesOrExportTarsAreProcessed(e);
        this.promptIfCsdInstallerDownloadIsInProgress(e);
        this.promptIfUserManualDownloadIsInProgress(e);
    }

    private promptIfUserManualDownloadIsInProgress(e: BeforeUnloadEvent) {
        this.mainModuleStoreService.userManualDownloadProgress$
            .pipe(
                take(1),
                filter((v) => v !== null)
            )
            .subscribe(() => this.showPrompt(e));
    }

    private promptIfCsdInstallerDownloadIsInProgress(e: BeforeUnloadEvent) {
        this.mainModuleStoreService.csdInstallerDownloadProgress$
            .pipe(
                take(1),
                filter((v) => v !== null)
            )
            .subscribe(() => this.showPrompt(e));
    }

    private promptIfUploadIsInProgress(e: BeforeUnloadEvent) {
        this.fileUploadService.uploadCompleted$
            .pipe(
                take(1),
                filter((v) => v === false)
            )
            .subscribe(() => this.showPrompt(e));
    }

    private promptIfExperimentDesignerIsInvalid(e: BeforeUnloadEvent) {
        this.persistingDataStorageService.hasUnsavedChangesInExperimentDesigner$
            .pipe(
                take(1),
                filter((v) => v === true && this.permission.isGranted('Pages.ExperimentDesigns.Edit'))
            )
            .subscribe(() => this.showPrompt(e));
    }

    private promptIfDownloadAllFilesOrExportTarsAreProcessed(e: BeforeUnloadEvent) {
        combineLatest([
            this.projectsRelatedStore.idsOfProjectsProcessingDownloadAllZpts$.pipe(map((idsSet) => idsSet.size !== 0)),
            this.projectsRelatedStore.idsOfProjectsProcessingExportTars$.pipe(map((idsSet) => idsSet.size !== 0)),
        ])
            .pipe(
                take(1),
                filter(([isDownloadProcessed, isExportProcessed]) => isDownloadProcessed || isExportProcessed)
            )
            .subscribe(() => this.showPrompt(e));
    }

    private showPrompt(e: BeforeUnloadEvent) {
        e.preventDefault(); // If you prevent default behavior in Mozilla Firefox prompt will always be shown
        e.returnValue = ''; // Chrome requires returnValue to be set
    }
}

import { ProjectsRelatedStore } from './../../app/main/projects/utils/projects-related-store.service';
import { ZPTFilesProcessed } from './../../app/main/zpt-files/zpt-files-processed.service';
import { NotifyService } from 'abp-ng2-module';
import { catchError, filter, finalize } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';
import { FileDto, IZonePlotTraceFileDto, ZonePlotTraceFileDto } from '@shared/service-proxies/service-proxies';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DateTime } from 'luxon';
import { EMPTY } from 'rxjs';

@Injectable()
export class FileDownloadService {
    constructor(
        private http: HttpClient,
        private notify: NotifyService,
        private projectsRelatedStore: ProjectsRelatedStore,
        private ZPTFilesProcessed: ZPTFilesProcessed
    ) {}

    downloadTempFile(file: FileDto) {
        const url =
            AppConsts.remoteServiceBaseUrl +
            '/File/DownloadTempFile?fileType=' +
            file.fileType +
            '&fileToken=' +
            file.fileToken +
            '&fileName=' +
            file.fileName;
        location.href = url; //TODO: This causes reloading of same page in Firefox
    }

    downloadAllZPTsFromProject(projectId: string) {
        const url =
            AppConsts.remoteServiceBaseUrl +
            '/ZonePlotTraceFile/DownloadZonePlotTraceFilesFromProject?projectReferenceId=' +
            projectId;

        this.notify.info('Please be patient, your download will start shortly.');
        this.getZPTs$(url)
            .pipe(finalize(() => this.projectsRelatedStore.deleteIdOfProjectProcessingDownloadAllZpts(projectId)))
            .subscribe((response) => {
                this.processToZPTDownload(response);
            });
    }

    downloadZPTFile(file: IZonePlotTraceFileDto) {
        const url =
            AppConsts.remoteServiceBaseUrl +
            '/ZonePlotTraceFile/DownloadZonePlotTraceFile?zonePlotTraceFileId=' +
            file.storageReference;

        this.getZPTs$(url)
            .pipe(finalize(() => this.ZPTFilesProcessed.delete(file.id)))
            .subscribe((response) => {
                file ? this.processToZPTDownload(response, file) : this.processToZPTDownload(response);
            });
    }

    processToExperimentExportDownload(response: HttpResponse<ArrayBuffer>) {
        // @todo: It could be changed to `processToFileDownload` method
        const data = response.body;
        const fileName =
            this.getFileNameFromContentDispositionHeader(response) ?? `export_${DateTime.now().toLocaleString()}`;

        const blob = new Blob([data], {
            type: 'application/zip',
        });

        const anchor = this.createHiddenAnchor(blob, fileName);

        this.startDownloadingAndRemoveAnchorAfterSuccess(anchor);
    }

    processToFileDownload(response: HttpResponse<ArrayBuffer>, alternateName: string, type?: 'application/zip') {
        const data = response.body;
        const fileName = this.getFileNameFromContentDispositionHeader(response) ?? alternateName;

        const blob = new Blob([data], {
            type,
        });

        const anchor = this.createHiddenAnchor(blob, fileName);

        this.startDownloadingAndRemoveAnchorAfterSuccess(anchor);
    }

    private createHiddenAnchor(blob: Blob, fileName: string): HTMLAnchorElement {
        const a = document.createElement('a');
        a.setAttribute('type', 'hidden');
        a.href = URL.createObjectURL(blob);
        a.download = fileName;

        return a;
    }

    private startDownloadingAndRemoveAnchorAfterSuccess(anchor: HTMLAnchorElement) {
        anchor.click();
        anchor.remove();
    }

    private processToZPTDownload(response: HttpResponse<Blob>, fileUpload?: IZonePlotTraceFileDto) {
        const data = response.body;
        const fileName = this.getFileNameFromContentDispositionHeader(response) ?? `${fileUpload.fileName}`;

        const anchor = this.createHiddenAnchor(data, fileName);
        this.startDownloadingAndRemoveAnchorAfterSuccess(anchor);
    }

    private getFileNameFromContentDispositionHeader(response: HttpResponse<any>): string {
        try {
            const startPhrase = 'filename=';
            const LENGTH_OF_START_PHRASE = 'filename='.length;

            const headerValue = response.headers.get('content-disposition');
            const start = headerValue.indexOf(startPhrase);
            const end = headerValue.indexOf('; ', start);

            return headerValue.slice(start + LENGTH_OF_START_PHRASE, end).replace(/"/g, '');
        } catch (e) {
            return null;
        }
    }

    private getZPTs$(url: string) {
        return this.http
            .get(url, {
                responseType: 'blob',
                observe: 'response',
            })
            .pipe(
                catchError((e) => {
                    const reader = new FileReader();
                    reader.onload = () => this.notify.error(JSON.parse(reader.result as string).result);
                    reader.readAsText(e.error);

                    return EMPTY;
                }),
                filter((response) => response.status === 200)
            );
    }
}

import { AppSessionService } from './../../../../../../shared/common/session/app-session.service';
import { LocalStorageService } from '@shared/utils/local-storage.service';
import { DateTime } from 'luxon';
import { GetExperimentDesignForViewDto, ProjectDto } from './../../../../../../shared/service-proxies/service-proxies';
import { GetProjectForViewDto, ExperimentDesignDto } from '@shared/service-proxies/service-proxies';
import { Injectable } from '@angular/core';

interface OpenedEntitiesPerUserId {
    [id: number]: {
        projects: OpenedEntity<GetProjectForViewDto>[];
        experimentDesigns: OpenedEntity<GetExperimentDesignForViewDto>[];
    };
}

interface OpenedEntity<T> {
    lastOpenedDateTime: string | DateTime;
    entity: T;
}

@Injectable({ providedIn: 'root' })
export class RecentlyOpenedEntitiesStorageService {
    static readonly LOCAL_STORAGE_KEY = 'recently_opened_entities';

    private storage: OpenedEntitiesPerUserId = {};

    private get storedProjectsForUser(): OpenedEntity<GetProjectForViewDto>[] {
        return this.storage[this.sessionService.userId].projects;
    }

    private get storedExperimentDesignsForUser(): OpenedEntity<GetExperimentDesignForViewDto>[] {
        return this.storage[this.sessionService.userId].experimentDesigns;
    }

    get storedAndSortedProjects() {
        return this.storedProjectsForUser.slice().sort(this.sortByLastOpenedInDescendingOrder);
    }

    get storedExperimentDesigns() {
        return this.storedExperimentDesignsForUser.slice().sort(this.sortByLastOpenedInDescendingOrder);
    }

    constructor(private sessionService: AppSessionService, private localStorageService: LocalStorageService) {}

    /**
     * Used to add entity to `storage`. If element is found in storage - it will replace it.
     * Will update local storage after this action.
     */
    addProject(newEntity: OpenedEntity<GetProjectForViewDto>) {
        let indexOfEntityInStorage = this.storedProjectsForUser.findIndex(
            (el) => el.entity.project.id === newEntity.entity.project.id
        );

        if (indexOfEntityInStorage > -1) {
            this.storedProjectsForUser[indexOfEntityInStorage] = newEntity;
        } else {
            this.storedProjectsForUser.push(newEntity);
        }

        this.saveToLocalStorage();
    }

    /**
     * Use this method if project have been updated, but its coupled {@link OpenedEntity} in `storage` have been
     * not updated. Usually update of `OpenedEntity` happens in level2 of `startBuildingBreadcrumbs` in `NavigationalBreadcrumbsService`,
     * but if breadcrumbs level2 are not available, there won't be update action in `storage`.
     * Will update local storage after this action.
     */
    updateExistingProject(project: ProjectDto) {
        let indexOfEntityInStorage = this.storedProjectsForUser.findIndex((el) => el.entity.project.id === project.id);

        if (indexOfEntityInStorage > -1) {
            this.storedProjectsForUser[indexOfEntityInStorage].entity.project = project;
        } else {
            console.warn('Update of project failed. Entity not found, id used');
        }

        this.saveToLocalStorage();
    }

    deleteProject(id: string) {
        let indexOfEntityInStorage = this.storedProjectsForUser.findIndex((el) => el.entity.project.id === id);

        if (indexOfEntityInStorage > -1) {
            this.storage[this.sessionService.userId].projects = this.storedProjectsForUser.filter(
                (el) => el.entity.project.id !== id
            );
        } else {
            console.warn('Delete of project failed. Entity not found, id used');
        }

        this.saveToLocalStorage();
    }

    /**
     * Used to add entity to `storage`. If element is found in storage - it will replace it.
     * Will update local storage after this action.
     */
    addExperimentDesign(newEntity: OpenedEntity<GetExperimentDesignForViewDto>) {
        let indexOfEntityInStorage = this.storedExperimentDesignsForUser.findIndex(
            (el) => el.entity.experimentDesign.id === newEntity.entity.experimentDesign.id
        );

        if (indexOfEntityInStorage > -1) {
            this.storedExperimentDesignsForUser[indexOfEntityInStorage] = newEntity;
        } else {
            this.storedExperimentDesignsForUser.push(newEntity);
        }

        this.saveToLocalStorage();
    }

    /**
     * Use this method if experimentDesign have been updated, but its coupled {@link OpenedEntity} in `storage` have been
     * not updated. Usually update of `OpenedEntity` happens in level2 of `startBuildingBreadcrumbs` in `NavigationalBreadcrumbsService`,
     * but if breadcrumbs level2 are not available, there won't be update action in `storage`.
     * Will update local storage after this action.
     */
    updateExistingExperimentDesign(experimentDesign: ExperimentDesignDto) {
        let indexOfEntityInStorage = this.storedExperimentDesignsForUser.findIndex(
            (el) => el.entity.experimentDesign.id === experimentDesign.id
        );

        if (indexOfEntityInStorage > -1) {
            this.storedExperimentDesignsForUser[indexOfEntityInStorage].entity.experimentDesign = experimentDesign;
        } else {
            console.warn(
                'Update of experiment design failed. Entity not found, id used. Perhaps experiment design has been updated (only templates are stored)'
            );
        }

        this.saveToLocalStorage();
    }

    /**
     * Loads stored value of `storage` property via `${@link LocalStorageService}`, then maps and assigns it
     * into `storage` property of this service.
     */
    loadFromLocalStorage() {
        this.localStorageService.getItem(
            RecentlyOpenedEntitiesStorageService.LOCAL_STORAGE_KEY,
            (err, v: OpenedEntitiesPerUserId) => {
                if (!v) {
                    this.initStorageState();
                    return;
                }

                this.storage = v;

                const storedValuesForUser = v[this.sessionService.userId];

                if (!storedValuesForUser) {
                    this.initStorageState();
                    return;
                }

                try {
                    storedValuesForUser?.projects.forEach((storedProject) =>
                        this.addProject({
                            entity: GetProjectForViewDto.fromJS(storedProject.entity),
                            lastOpenedDateTime: DateTime.fromISO(storedProject.lastOpenedDateTime as string),
                        })
                    );

                    storedValuesForUser?.experimentDesigns.forEach((storedExperimentDesign) => {
                        this.addExperimentDesign({
                            entity: GetExperimentDesignForViewDto.fromJS(storedExperimentDesign.entity),
                            lastOpenedDateTime: DateTime.fromISO(storedExperimentDesign.lastOpenedDateTime as string),
                        });
                    });
                } catch (error) {
                    console.error(error);
                    this.initStorageState();
                }
            }
        );
    }

    private initStorageState() {
        this.storage[this.sessionService.userId] = {
            experimentDesigns: [],
            projects: [],
        };
    }

    /**
     * Saves value of `storage` to some local storage. That depends on {@link LocalStorageService} service.
     */
    private saveToLocalStorage() {
        new LocalStorageService().setItem(RecentlyOpenedEntitiesStorageService.LOCAL_STORAGE_KEY, this.storage);
    }

    private sortByLastOpenedInDescendingOrder(
        a: OpenedEntity<GetProjectForViewDto | GetExperimentDesignForViewDto>,
        b: OpenedEntity<GetProjectForViewDto | GetExperimentDesignForViewDto>
    ): -1 | 0 | 1 {
        const _a = a.lastOpenedDateTime.valueOf() as number;
        const _b = b.lastOpenedDateTime.valueOf() as number;

        return _a === _b ? 0 : _a > _b ? -1 : 1;
    }
}

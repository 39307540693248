import { takeUntil } from 'rxjs/operators';
import { Subject, timer } from 'rxjs';
import { state, style, animate, transition, trigger } from '@angular/animations';
import { Component, OnDestroy } from '@angular/core';

@Component({
    selector: 'app-animated-text-with-gradient',
    templateUrl: './animated-text-with-gradient.component.html',
    styleUrls: ['./animated-text-with-gradient.component.scss'],
    animations: [
        trigger('changeColors', [
            state(
                'blue',
                style({
                    color: '#fff',
                    background: 'linear-gradient(90deg, rgb(8,0,147) 0%, rgb(0,0,0) 100%)',
                })
            ),
            state(
                'orange',
                style({
                    color: '#fff',
                    background: 'linear-gradient(to right, #f2994a, #f2c94c)',
                })
            ),
            state(
                'green',
                style({
                    color: '#fff',
                    background: 'linear-gradient(90deg, rgb(150,218,19) 0%, rgb(219,255,0) 100%)',
                })
            ),
        ]),
        trigger('blink', [
            state('out', style({ opacity: 0, width: '0' })),
            state('in', style({ opacity: 1, width: '*' })),
            transition('* <=> *', animate('800ms 0ms cubic-bezier(.33,-0.42,.55,1.45)')),
        ]),
    ],
})
export class AnimatedTextWithGradientComponent implements OnDestroy {
    readonly stateCollection = [
        { text: 'Myocyte', stateName: 'blue' },
        { text: 'Calcium-Contractility data', stateName: 'orange' },
        { text: 'Ratiometric', stateName: 'green' },
    ];
    state = this.stateCollection[0].stateName;
    indexOfState = 0;
    blinkStateIn = true;

    private componentDestroyed$ = new Subject();
    private timer$ = timer(3000, 5000)
        .pipe(takeUntil(this.componentDestroyed$))
        .subscribe(() => this.change());

    ngOnDestroy() {
        this.componentDestroyed$.next();
    }

    private change() {
        /**
         * This value should be slightly smaller than the value declared in "blink" state transition
         */
        const timeout = 600;
        this.blinkStateIn = false;

        setTimeout(() => {
            this.blinkStateIn = true;
            if (this.indexOfState === this.stateCollection.length - 1) {
                this.indexOfState = 0;
                this.state = this.stateCollection[0].stateName;
                return;
            }

            this.state = this.stateCollection[this.indexOfState + 1].stateName;
            this.indexOfState++;
        }, timeout);
    }
}

import { AppConsts } from '@shared/AppConsts';

export class ZPTFileUploadConsts {
    static get UPLOAD_URL() {
        return AppConsts.remoteServiceBaseUrl + '/ZonePlotTraceFile/UploadZonePlotTraceFile';
    }
    static readonly USE_PACING_MARKS = true;
    static readonly USE_BACKGROUND_CONSTANT = false;
    static readonly ANALYSE_DELETED_CELLS = false;
    static readonly OFFSET_ON_CALCULATED_TRANSIENT_START = 0.01;
    static readonly THRESHOLD_VALUE = 0.08;
    static readonly SKIP_TIME_VALUE = 0.1;
    static readonly CONTRACTION_ESCAPE_VALUE = 0.1;
    static readonly IRREGULAR_RETURN_VALUE = 0.15;
    /**
     * This is a constant that is defined by analysis configuration available for ZPT file (offset, time-skip etc...)
     */
    static readonly AMOUNT_OF_ANALYSIS_CONFIGURATIONS_PER_FILE = 8;
}

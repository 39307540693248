import { LocallyStored } from '@shared/decorators/locally-stored.decorator';
import { NavigationalElement } from '../../../models/navigational-element';
import { SidebarInstancesTypes } from '../enums/sidebar-instances-types.enum';

@LocallyStored
export class SidebarInstanceElement {
    /**
     * @param {string} name - Visible name of instance.
     * @param {SidebarInstancesTypes} type - One of available types of instance.
     * @param {string} id - Guid of stored entity.
     * @param {NavigationalElement[]} items - Elements with navigation feature that will be generated as
     * sub elements when instance is expanded (controlled by `isOpened`).
     * @param {string[]} route - If instance does not contain sub elements, it will become clickable and
     * redirect to specified route.
     * @param {boolean} isOpened - Represents UI state of instance, whether it is expanded or not.
     * @param {boolean} isEnabled - Represents UI state of instance, usable for single instance only. Disabled instance
     * won't allow its main action (e.g. Experiment design instance will not redirect user on click)
     */
    constructor(
        public name: string,
        public type: SidebarInstancesTypes,
        public id: string,
        public items: NavigationalElement[],
        public route: string[],
        public isOpened: boolean,
        public isEnabled = true
    ) {}
}

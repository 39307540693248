import { SharedInputPropertiesDirective } from './../shared-input-properties.directive';
import { Component, ViewEncapsulation, ChangeDetectionStrategy, Input } from '@angular/core';
import { ErrorDef } from '@shared/utils/validation-messages.component';

@Component({
    selector: 'app-date-input',
    templateUrl: './date-input.component.html',
    styleUrls: ['./../shared-input-styles.scss'],
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DateInputComponent extends SharedInputPropertiesDirective {
    @Input() minStartDate: Date;

    dateInputErrorDefs: ErrorDef[] = [
        {
            error: 'invalidDate',
            localizationKey: 'Invalid date format',
        } as ErrorDef,
    ];
}

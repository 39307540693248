import { Component, OnInit, ChangeDetectionStrategy, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { FileUploadService } from '@app/main/projects/modals/upload-files/file-upload-wrapper/file-upload-wrapper.service';
import { FileUploadStatusEnum } from '@app/main/projects/modals/upload-files/file-upload-wrapper/models/enums/file-upload-status.enum';
import { UploaderComponentStatus } from '@app/main/projects/modals/upload-files/file-upload-wrapper/models/enums/uploader-component-status.enum';
import { FileWithProgressToTrack } from '@app/main/projects/modals/upload-files/file-upload-wrapper/models/interfaces/file-with-progress-to-track.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-floating-upload-widget',
    templateUrl: './floating-upload-widget.component.html',
    styleUrls: ['./floating-upload-widget.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FloatingUploadWidgetComponent implements OnInit, OnDestroy {
    projectsCount = 0;
    filesCount = 0;
    uploadedCount = 0;
    failedCount = 0;
    uploadCompleted = false;

    private statusSub = new Subscription();
    private fileUploadStatusEnum = FileUploadStatusEnum;

    constructor(private fileUploadService: FileUploadService, private cdRef: ChangeDetectorRef) {}

    ngOnInit() {
        this.statusSub.add(
            this.fileUploadService.uploadStatus$.subscribe((data: FileWithProgressToTrack[]) => {
                let projectsSet = new Set();
                let uploadedCount = 0;
                let failedCount = 0;

                data.forEach((file) => {
                    projectsSet.add(file.projectId);

                    if (file?.status === this.fileUploadStatusEnum.ok) {
                        uploadedCount++;
                    }

                    if (file?.status === this.fileUploadStatusEnum.failed) {
                        failedCount++;
                    }
                });

                this.uploadCompleted = false;
                this.projectsCount = projectsSet.size;
                this.filesCount = data.length;
                this.uploadedCount = uploadedCount;
                this.failedCount = failedCount;

                this.cdRef.markForCheck();
            })
        );

        this.statusSub.add(
            this.fileUploadService.uploadCompleted$.subscribe((v) => {
                this.uploadCompleted = v;
                this.cdRef.markForCheck();
            })
        );
    }

    ngOnDestroy() {
        this.statusSub.unsubscribe();
    }

    onClose() {
        this.uploadCompleted
            ? (this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.absent)
            : (this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.minimized);
    }

    onWidgetClick() {
        this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.modal;
    }
}

import { SidebarInstancesFactory } from '@app/shared/layout/nav/sidebar-instance/sidebar-instances-factory.service';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SidebarInstanceElement } from '@app/shared/layout/nav/sidebar-instance/models/classes/sidebar-instance-element.class';
import { Router } from '@angular/router';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { MessageService } from 'abp-ng2-module';
import { AppSessionService } from '@shared/common/session/app-session.service';

interface SidebarInstancesPerUserId {
    [id: number]: SidebarInstanceElement[];
}

@Injectable({
    providedIn: 'root',
})
export class SidebarInstancesService {
    items$ = new BehaviorSubject<SidebarInstanceElement[]>([]);
    readonly localStorageKeyName = 'storage_items_per_user_id';

    set sidebarInstancesForCurrentUserInMainObject(sidebarInstancesForCurrentUser: SidebarInstanceElement[]) {
        this._sidebarInstancesPerUserId[this.sessionService.userId] = sidebarInstancesForCurrentUser;
    }
    get sidebarInstancesForCurrentUserInMainObject(): SidebarInstanceElement[] {
        return this._sidebarInstancesPerUserId[this.sessionService.userId];
    }

    private _sidebarInstancesPerUserId: SidebarInstancesPerUserId;
    private _sidebarInstancesForCurrentUser: SidebarInstanceElement[] = [];

    constructor(
        private router: Router,
        private message: MessageService,
        private sessionService: AppSessionService,
        private sidebarInstancesFactory: SidebarInstancesFactory
    ) {}

    addOrUpdateItem(providedItem: SidebarInstanceElement) {
        this._sidebarInstancesForCurrentUser = this._sidebarInstancesForCurrentUser.map((sidebarInstance) => {
            if (sidebarInstance.id === providedItem.id) {
                providedItem.isOpened = true;
                return providedItem;
            }

            return sidebarInstance;
        });

        if (this._sidebarInstancesForCurrentUser.every((el) => el.id !== providedItem.id)) {
            this._sidebarInstancesForCurrentUser.unshift(providedItem);
        }

        this.updateSidebarInstancesForCurrentUserInMainObjectAndEmitCurrentItemsToObservables();
    }

    deleteItem(id: string) {
        this._sidebarInstancesForCurrentUser = this._sidebarInstancesForCurrentUser.filter(
            (sidebarInstance) => sidebarInstance.id !== id
        );
        this.updateSidebarInstancesForCurrentUserInMainObjectAndEmitCurrentItemsToObservables();
    }

    saveSidebarInstancesToLocalStorage() {
        localStorage.setItem(this.localStorageKeyName, JSON.stringify(this._sidebarInstancesPerUserId));
    }

    handleNonExistingInstance(message: string, instanceId: string, routeToNavigate?: string[]) {
        this.message.error(message);
        this.deleteItem(instanceId);

        if (routeToNavigate) {
            this.router.navigate(routeToNavigate);
        }
    }

    reorderSidebarInstances($event: CdkDragDrop<SidebarInstanceElement[]>) {
        moveItemInArray(this._sidebarInstancesForCurrentUser, $event.previousIndex, $event.currentIndex);

        this.updateSidebarInstancesForCurrentUserInMainObjectAndEmitCurrentItemsToObservables();
    }

    initializeInstancesStorage() {
        this.getSidebarInstancesFromLocalStorage();

        const objectsFromStorageForCurrentUser = this.sidebarInstancesForCurrentUserInMainObject ?? [];

        this._sidebarInstancesForCurrentUser = objectsFromStorageForCurrentUser
            .map((el) => this.mapToSidebarInstanceClass(el))
            .filter((instance) => !!instance);

        this.updateSidebarInstancesForCurrentUserInMainObjectAndEmitCurrentItemsToObservables();
    }

    private updateSidebarInstancesForCurrentUserInMainObjectAndEmitCurrentItemsToObservables() {
        this.sidebarInstancesForCurrentUserInMainObject = this._sidebarInstancesForCurrentUser;
        this.saveSidebarInstancesToLocalStorage();
        this.items$.next(this.sidebarInstancesForCurrentUserInMainObject);
    }

    private getSidebarInstancesFromLocalStorage() {
        this._sidebarInstancesPerUserId = JSON.parse(localStorage.getItem(this.localStorageKeyName)) || {};
    }

    private mapToSidebarInstanceClass(el: Partial<SidebarInstanceElement>): SidebarInstanceElement {
        return this.sidebarInstancesFactory.recreate(el);
    }
}

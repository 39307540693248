import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss'],
})
export class LoadingSpinnerComponent {
    /**
     * If set to 0, it will cover space of position-relative parent
     */
    @Input() minHeight = 100;
    /**
     * Adds half opaque background for container
     */
    @Input() showOverlay = false;
}

/**
 * A class that has single static method, that will allow to test provided `schema` against `input`.
 *
 * Use it if you want to ensure that provided `input` object has keys that you expect, and their values is of
 * type that you expect.
 */
export class SchemaValidator {
    /**
     * @param input - An object that will be used to create new class. It will be checked
     * against `schema` parameter.
     * @param schema - An object that will validate correctness of input.
     */
    static validate(
        input: any,
        schema: { [key: string]: 'String' | 'Boolean' | 'Array' | 'Object' | 'Number' }
    ): boolean {
        const isProperInstance = (input: any) => {
            const missingProperties = Object.keys(schema)
                .filter((schemaKey) => {
                    // schema key in input is undefined OR constructor type is not matching the one described by schema
                    return input[schemaKey] === undefined || input[schemaKey]?.constructor.name !== schema[schemaKey];
                })
                .map((key) => console.error(`Input has problem with "${key}" (constructor name: ${schema[key]})`));

            return missingProperties.length === 0;
        };

        return isProperInstance(input);
    }
}

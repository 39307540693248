<div [class]="currentTheme.baseSettings.subHeader.containerStyle">
    <div [class]="containerClass + ' d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap'">
        <div class="d-flex align-items-center flex-wrap mr-2">
            <ng-content select="div.extras-before-title"></ng-content>

            <h1
                [attr.data-e2e-hook]="'subheader-title'"
                *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 1"
                [class]="currentTheme.baseSettings.subHeader.titleStlye"
            >
                {{ title }}
            </h1>
            <h2
                [attr.data-e2e-hook]="'subheader-title'"
                *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 2"
                [class]="currentTheme.baseSettings.subHeader.titleStlye"
            >
                {{ title }}
            </h2>
            <h3
                [attr.data-e2e-hook]="'subheader-title'"
                *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 3"
                [class]="currentTheme.baseSettings.subHeader.titleStlye"
            >
                {{ title }}
            </h3>
            <h4
                [attr.data-e2e-hook]="'subheader-title'"
                *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 4"
                [class]="currentTheme.baseSettings.subHeader.titleStlye"
            >
                {{ title }}
            </h4>
            <h5
                [attr.data-e2e-hook]="'subheader-title'"
                *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 5"
                [class]="currentTheme.baseSettings.subHeader.titleStlye"
            >
                {{ title }}
            </h5>
            <h6
                [attr.data-e2e-hook]="'subheader-title'"
                *ngIf="currentTheme.baseSettings.subHeader.subheaderSize == 6"
                [class]="currentTheme.baseSettings.subHeader.titleStlye"
            >
                {{ title }}
            </h6>
            <div
                *ngIf="description"
                class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>
            <div *ngIf="description" class="d-flex align-items-center">
                <span class="text-muted font-weight-bold mr-4">{{ description }}</span>
            </div>
            <div
                *ngIf="breadcrumbs && breadcrumbs.length > 0"
                class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"
            ></div>
            <ul
                *ngIf="breadcrumbs && breadcrumbs.length > 0"
                class="breadcrumb breadcrumb-transparent breadcrumb-dot font-weight-bold p-0 my-2 font-size-sm"
            >
                <li class="breadcrumb-item" *ngFor="let breadcrumbItem of breadcrumbs">
                    <a
                        *ngIf="breadcrumbItem.isLink()"
                        style="cursor: pointer"
                        (click)="goToBreadcrumb(breadcrumbItem)"
                        class="text-muted"
                    >
                        {{ breadcrumbItem.text }}
                    </a>
                    <span *ngIf="!breadcrumbItem.isLink()" class="text-muted">{{ breadcrumbItem.text }}</span>
                </li>
            </ul>
        </div>
        <div class="d-flex align-items-center">
            <ng-content select="div[role=actions]"></ng-content>
        </div>
    </div>
</div>

import { Component, OnInit, ChangeDetectionStrategy, ViewChild, OnDestroy } from '@angular/core';
import { FileUploadService } from '@app/main/projects/modals/upload-files/file-upload-wrapper/file-upload-wrapper.service';
import { UploaderComponentStatus } from '@app/main/projects/modals/upload-files/file-upload-wrapper/models/enums/uploader-component-status.enum';
import { ModalDirective } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { FileWithProgressToTrack } from '../upload-files/file-upload-wrapper/models/interfaces/file-with-progress-to-track.interface';

@Component({
    selector: 'app-file-upload-progress-modal',
    templateUrl: './file-upload-progress-modal.component.html',
    styleUrls: ['./file-upload-progress-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileUploadProgressModalComponent implements OnInit, OnDestroy {
    @ViewChild('uploadFilesProgressModal', { static: true }) modal: ModalDirective;

    uploadStatus$ = this.fileUploadService.uploadStatus$.pipe(
        map((data: FileWithProgressToTrack[]) => {
            const x = [];

            data.forEach((file) => {
                const objWithSameProjectIdFound = x.find((el) => el.projectId === file.projectId);

                if (objWithSameProjectIdFound) {
                    objWithSameProjectIdFound.files.push(file);
                    return;
                }

                x.push({
                    projectId: file.projectId,
                    projectName: file.projectName,
                    files: [file],
                });
            });

            return x;
        })
    );

    uploadCompleted = false;

    private uploadCompletedSub: Subscription;

    constructor(private fileUploadService: FileUploadService) {}

    ngOnInit() {
        this.modal.show();

        this.uploadCompletedSub = this.fileUploadService.uploadCompleted$.subscribe((v) => (this.uploadCompleted = v));

        this.modal.onHide.pipe(take(1)).subscribe(() => this.assignUploaderComponentStatus());
    }

    ngOnDestroy() {
        this.uploadCompletedSub.unsubscribe();
    }

    close() {
        this.modal.hide();

        this.assignUploaderComponentStatus();
    }

    minimize() {
        this.modal.hide();

        this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.fab;
    }

    private assignUploaderComponentStatus() {
        this.uploadCompleted
            ? (this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.absent)
            : (this.fileUploadService.uploaderComponentStatus = UploaderComponentStatus.minimized);
    }
}

import { environment } from './../../../../../../environments/environment';
import { ZPTFileUploadConsts } from './ZPT-file-upload-consts';
import {
    HttpClient,
    HttpErrorResponse,
    HttpEvent,
    HttpEventType,
    HttpProgressEvent,
    HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ProjectPageService } from '@app/main/projects/project-page/project-page.service';
import { DynamicComponentsService } from '@app/main/shared/dynamic-components.service';
import { FloatingUploadWidgetComponent } from '@app/main/widgets/floating-upload-widget/floating-upload-widget.component';
import { FileUploadProgressModalComponent } from '@app/main/projects/modals/file-upload-progress-modal/file-upload-progress-modal.component';
import { FileUploadButtonComponent } from '@app/shared/layout/topbar/file-upload-button/file-upload-button.component';
import { NotifyService } from 'abp-ng2-module';
import { Observable, Subject, iif, EMPTY, Subscription, BehaviorSubject } from 'rxjs';
import { catchError, concatMap, delay } from 'rxjs/operators';
import { FileUploadStatusEnum } from './models/enums/file-upload-status.enum';
import { UploaderComponentStatus } from './models/enums/uploader-component-status.enum';
import { DTOForZPTToUpload } from './models/interfaces/ZPT-file-upload-data.interface';
import { FileWithProgressToTrack } from './models/interfaces/file-with-progress-to-track.interface';

@Injectable({
    providedIn: 'root',
})
export class FileUploadService {
    static readonly MAX_BATCH_COUNT = 20 as const;
    static readonly MAX_BATCH_SIZE = 524288000 as const; // 500MB in bytes
    static readonly MAX_FILE_SIZE = 26214400 as const; // 25MB in bytes
    static readonly ONE_MB_IN_BYTES = 1048576 as const;

    requestsArray: Observable<HttpEvent<any> | HttpErrorResponse>[] = [];

    private currentlyUploadedFileIndex = 0;
    private fileUploadSub = new Subscription();
    private uploadsForTracking: FileWithProgressToTrack[] = [];
    private isUploadingInProgress = false;
    private fileUploadStatusEnum = FileUploadStatusEnum;

    private _uploadCompleted$ = new BehaviorSubject<boolean>(null);
    uploadCompleted$ = this._uploadCompleted$.asObservable();

    private _uploadStatus$ = new BehaviorSubject<FileWithProgressToTrack[]>([]);
    uploadStatus$ = this._uploadStatus$.asObservable();

    private startProcessingFileUploading$ = new Subject();

    private _uploaderComponentStatus = UploaderComponentStatus.absent;
    set uploaderComponentStatus(status: UploaderComponentStatus) {
        this.destroyAllComponentsRelatedToUploadProgress();

        switch (status) {
            case UploaderComponentStatus.minimized: {
                this.dynamicComponentsService.appendComponentToBody(FileUploadButtonComponent);
                break;
            }
            case UploaderComponentStatus.fab: {
                this.dynamicComponentsService.appendComponentToBody(FloatingUploadWidgetComponent);
                break;
            }
            case UploaderComponentStatus.modal: {
                this.dynamicComponentsService.appendComponentToBody(FileUploadProgressModalComponent);
            }
        }

        this._uploaderComponentStatus = status;
    }
    get uploaderComponentStatus(): UploaderComponentStatus {
        return this._uploaderComponentStatus;
    }

    constructor(
        private httpClient: HttpClient,
        private notify: NotifyService,
        private dynamicComponentsService: DynamicComponentsService,
        private projectPageService: ProjectPageService
    ) {
        this.fileUploadSub = this.uploadOnFileProcessStreamEmission();
    }

    handleFileUpload(data: DTOForZPTToUpload[]): void {
        if (!this.isUploadingInProgress) {
            this.uploadsForTracking = [];
            this.currentlyUploadedFileIndex = 0;
        }

        this.uploaderComponentStatus = UploaderComponentStatus.fab;

        this.sendFilesToServer(data);
    }

    private sendFilesToServer(data: DTOForZPTToUpload[]) {
        this.isUploadingInProgress = true;
        this._uploadCompleted$.next(false);

        this.populateRequestsArrayAndTrackingObjects(data);

        this._uploadStatus$.next(this.uploadsForTracking);
        this.startProcessingFileUploading$.next();
    }

    private populateRequestsArrayAndTrackingObjects(data: DTOForZPTToUpload[]) {
        data.forEach((dto) => {
            const req = this.createHttpRequest(dto);

            const fileToTrack: FileWithProgressToTrack = {
                name: dto.file.name,
                size: dto.file.size,
                projectId: this.projectPageService.selectedProject.id,
                projectName: this.projectPageService.selectedProject.name,
                progress: undefined,
                status: undefined,
            };

            this.uploadsForTracking.push(fileToTrack);

            this.requestsArray.push(
                this.httpClient.request<HttpEvent<any> | HttpErrorResponse>(req).pipe(
                    delay(environment.variables.ZPT_UPLOAD_DELAY),
                    catchError((err) => this.markFileAsFailedAndContinueStream())
                )
            );
        });
    }

    private uploadOnFileProcessStreamEmission(): Subscription {
        return this.startProcessingFileUploading$
            .pipe(concatMap(() => iif(() => !!this.requestsArray[0], this.requestsArray[0], EMPTY)))
            .subscribe((response: HttpEvent<any> | HttpErrorResponse) => {
                if (response.type === HttpEventType.UploadProgress) {
                    this.markUploadStatusAsInProgress(response);
                }

                if (response.type === HttpEventType.Response) {
                    this.markUploadStatusAsDone();
                    this.currentlyUploadedFileIndex++;

                    this.requestsArray.shift();

                    this._uploadStatus$.next(this.uploadsForTracking);

                    if (this.requestsArray.length > 0) {
                        this.startProcessingFileUploading$.next();
                    } else {
                        this.onUploadingComplete();
                    }
                }
            });
    }

    private onUploadingComplete() {
        this.isUploadingInProgress = false;

        this.notify.success('Uploading process has been completed.', undefined, {
            position: 'top-end',
        });

        this._uploadStatus$.next(this.uploadsForTracking);
        this._uploadCompleted$.next(true);
    }

    private createHttpRequest(dto: DTOForZPTToUpload): HttpRequest<any> {
        const formData = this.createFormData(dto);

        const options = {
            reportProgress: true,
        };

        return new HttpRequest('POST', ZPTFileUploadConsts.UPLOAD_URL, formData, options);
    }

    private createFormData(dto: DTOForZPTToUpload): FormData {
        const formData = new FormData();

        formData.append('file', dto.file);
        formData.append('projectId', dto.projectId);
        formData.append('comment', '');
        formData.append('analyseDeletedCells', '' + dto.isAnalyzeDiscardedChecked);
        formData.append('useBackgroundConstant', '' + dto.isBackgroundConstantsChecked);
        formData.append('usePacingMarks', '' + dto.isPacingMarksChecked);
        formData.append('cep', '' + dto.contractionEscapeThreshold);
        formData.append('irp', '' + dto.irregularReturnThreshold);

        if (dto.offsetTime !== null) {
            formData.append('offsetOnCalculatedTransientStart', '' + dto.offsetTime);
        }

        if (dto.calciumThreshold !== null) {
            formData.append('threshold', '' + dto.calciumThreshold);
        }

        if (dto.calciumTimeSkip !== null) {
            formData.append('skipTime', '' + dto.calciumTimeSkip);
        }

        return formData;
    }

    private markFileAsFailedAndContinueStream(): Observable<HttpEvent<any> | HttpErrorResponse> {
        this.uploadsForTracking[this.currentlyUploadedFileIndex].status = this.fileUploadStatusEnum.failed;
        this._uploadStatus$.next(this.uploadsForTracking);
        this.currentlyUploadedFileIndex++;
        this.requestsArray.shift();

        if (!!this.requestsArray[0]) {
            return this.requestsArray[0];
        } else {
            this.onUploadingComplete();
            return EMPTY;
        }
    }

    private markUploadStatusAsDone() {
        this.uploadsForTracking[this.currentlyUploadedFileIndex].status = this.fileUploadStatusEnum.ok;
        this.uploadsForTracking[this.currentlyUploadedFileIndex].progress = null;
    }

    private markUploadStatusAsInProgress(response: HttpProgressEvent) {
        this.uploadsForTracking[this.currentlyUploadedFileIndex].progress = +(response.loaded / response.total).toFixed(
            2
        );
        this._uploadStatus$.next(this.uploadsForTracking);
    }

    private destroyAllComponentsRelatedToUploadProgress() {
        this.dynamicComponentsService.destroyComponent(FloatingUploadWidgetComponent);
        this.dynamicComponentsService.destroyComponent(FileUploadButtonComponent);
        this.dynamicComponentsService.destroyComponent(FileUploadProgressModalComponent);
    }
}

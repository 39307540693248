import { Injectable } from '@angular/core';
import { ApplicationInsights, IExceptionTelemetry } from '@microsoft/applicationinsights-web';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class ApplicationInsightsService {
    appInsights: ApplicationInsights;

    constructor(private router: Router) {}

    setUserId(userId: string) {
        this.appInsights.setAuthenticatedUserContext(userId);
    }

    clearUserId() {
        this.appInsights.clearAuthenticatedUserContext();
    }

    logPageView(name?: string, uri?: string) {
        this.appInsights.trackPageView({
            name,
            uri,
        });
    }

    logException(error: Error) {
        let exception: IExceptionTelemetry = {
            exception: error,
        };
        this.appInsights.trackException(exception);
    }

    loadCustomTelemetryProperties() {
        this.appInsights.addTelemetryInitializer((envelope) => {
            const item = envelope.baseData;
            item.properties = item.properties || {};
            item.properties['ApplicationPlatform'] = 'Web';
            item.properties['ApplicationName'] = 'CytoSolver Cloud Angular';
        });
    }

    createRouterSubscription() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.logPageView(null, event.urlAfterRedirects);
        });
    }
}

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CscAlertComponent } from './csc-alert/csc-alert.component';
import { CytoSolverCommonModule } from '@shared/common/common.module';

@NgModule({
    declarations: [CscAlertComponent],
    imports: [CommonModule, CytoSolverCommonModule],
    exports: [CscAlertComponent]
})
export class CscAlertModule {}

import { Observable } from 'rxjs/internal/Observable';
import { Directive, Input, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';
import { EMPTY, iif, of, Subscription, timer } from 'rxjs';
import { switchMap, take, tap } from 'rxjs/operators';
import { ScrollContentToBeVisibleAnimationDataInterface } from '@app/main/transient-analysis-results/transient-analysis-results-page/components/trace-visualization/components/trace-data-table/scroll-content-to-be-visible-animation-data.interface';

// @todo: On hover maybe remove animation?

@Directive({
    selector: '[appScrollContentToBeVisible][selector][trigger]',
})
export class ScrollContentToBeVisibleDirective implements AfterViewInit, OnDestroy {
    @Input() trigger: Observable<void>;
    /**
     * Selector used to find element, that should be visible
     */
    @Input() selector: string;
    /**
     * Configuration option for animation
     *
     */
    @Input() animationAfterTrigger?: ScrollContentToBeVisibleAnimationDataInterface;
    @Input() scrollIntoViewOptions: Pick<ScrollIntoViewOptions, 'block'> = {
        block: 'start',
    };

    private sub = new Subscription();

    constructor(private host: ElementRef<HTMLElement>) {}

    ngAfterViewInit() {
        let targetElement: HTMLElement;

        if (this.trigger) {
            this.sub = this.trigger
                ?.pipe(
                    tap(() => {
                        targetElement = this.host.nativeElement.querySelector(this.selector);

                        if (targetElement) {
                            targetElement.scrollIntoView(this.scrollIntoViewOptions);
                        } else {
                            this.host.nativeElement.scrollTop = 0;
                        }
                    }),
                    switchMap(() => iif(() => this.animationAfterTrigger !== undefined, of('just_proceed'), EMPTY)),
                    switchMap(() => timer(this.animationAfterTrigger.timeOffset).pipe(take(1))),
                    tap(() => targetElement.classList.add(this.animationAfterTrigger.cssClassWithAnimation)),
                    switchMap(() => timer(this.animationAfterTrigger.duration).pipe(take(1))),
                    tap(() => targetElement.classList.remove(this.animationAfterTrigger.cssClassWithAnimation))
                )
                .subscribe();
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }
}

import { FileDownloadService } from './../../../shared/utils/file-download.service';
import { AppConsts } from '@shared/AppConsts';
import { MainModuleStoreService } from './../main-module-store.service';
import { Injectable } from '@angular/core';
import { catchError, filter, finalize, tap } from 'rxjs/operators';
import { HttpClient, HttpEventType, HttpProgressEvent, HttpResponse } from '@angular/common/http';
import { EMPTY } from 'rxjs';
import { NotifyService } from 'abp-ng2-module';

@Injectable({ providedIn: 'root' })
export class UserManualDownloadService {
    constructor(
        private mainModuleStoreService: MainModuleStoreService,
        private http: HttpClient,
        private notify: NotifyService,
        private fileDownloadService: FileDownloadService
    ) {}

    triggerFileDownload() {
        this.mainModuleStoreService.updateUserManualDownloadProgress(0);

        this.http
            .get(AppConsts.remoteServiceBaseUrl + '/CytoSolverUserManual/GetLatest', {
                responseType: 'arraybuffer',
                observe: 'events',
                reportProgress: true,
            })
            .pipe(
                catchError(() => {
                    this.notify.error(
                        'Something went wrong with CytoSolver Manual downloading. Please try again later'
                    );

                    return EMPTY;
                }),
                tap((v: HttpResponse<ArrayBuffer> | HttpProgressEvent) => {
                    if (v?.type === HttpEventType.DownloadProgress) {
                        const progressInPercents = +((v.loaded / v.total) * 100).toFixed(0);

                        this.mainModuleStoreService.updateUserManualDownloadProgress(progressInPercents);
                    }
                }),
                filter((response) => response instanceof HttpResponse),
                finalize(() => {
                    this.mainModuleStoreService.updateUserManualDownloadProgress(null);
                })
            )
            .subscribe((data: HttpResponse<ArrayBuffer>) =>
                this.fileDownloadService.processToFileDownload(data, 'CytoSolver User Manual')
            );
    }
}

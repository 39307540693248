import { ProjectDto } from '@shared/service-proxies/service-proxies';
import { NavigationalElement } from '../models/navigational-element';
import { PermissionsAndFeaturesResolverService } from '@shared/permissions-and-features-resolver/permissions-and-features-resolver.service';

export class ProjectRelatedNavigation {
    items: NavigationalElement[] = [];

    constructor(
        private project: ProjectDto,
        private isDataVisAvailable: boolean,
        private permissionsAndFeaturesResolverService: PermissionsAndFeaturesResolverService
    ) {
        this.createItems();
    }

    private createItems() {
        const { items, project, isDataVisAvailable } = this;
        const overviewPathWithProjectId = ['/app/main/projects-overview', project.id];

        items.push(
            new NavigationalElement(
                'Overview',
                'fa-light fa-eye',
                ['/app/main/projects-overview', project.id],
                this.permissionsAndFeaturesResolverService.isAllowed('projects.canRead')
            )
        );

        items.push(
            new NavigationalElement(
                'Transient analysis',
                'fa-light fa-wave-pulse',
                [...overviewPathWithProjectId, 'transient-analysis'],
                this.permissionsAndFeaturesResolverService.isAllowed('transientAnalysis.canRead')
            )
        );

        items.push(
            new NavigationalElement(
                'Annotation',
                'fa-light fa-pencil',
                [...overviewPathWithProjectId, 'data-annotation'],
                this.permissionsAndFeaturesResolverService.isAllowed('dataAnnotation.canRead') &&
                    !!project.experimentDesignId
            )
        );

        items.push(
            new NavigationalElement(
                'Data visualization',
                'fa-light fa-chart-scatter-bubble',
                [...overviewPathWithProjectId, 'data-visualization'],
                this.permissionsAndFeaturesResolverService.isAllowed('dataVisualization.canRead') && isDataVisAvailable
            )
        );

        items.push(
            new NavigationalElement(
                'Experiment design',
                'fa-light fa-compass-drafting',
                [...overviewPathWithProjectId, 'experiment-design-preview'],
                this.permissionsAndFeaturesResolverService.isAllowed('experimentDesign.canRead') &&
                    !!project.experimentDesignId
            )
        );
    }
}
